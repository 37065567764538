// Constants
import {
  FIN_WHITE_LIST_STATUS_SUCCESS,
  GET_FIN_WHITE_LIST_CSV_TEMPLATE_SUCCESS,
  GET_FIN_WHITE_LIST_TENTANTS_SUCCESS,
  UPLOAD_FIN_WHITE_LIST_CSV_RESPONSE_SUCCESS,
} from "../../../constants/FinwhiteListConstants";
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";

import { toast } from "react-toastify";

// Service
import { finWhiteListService } from "../../services/appLoginDashboard/FinWhiteListService";

// Core Components
import NotificationMessage from "../../../components/core/NotificationMessage";

function finWhiteListCSVResponseSucess(resp) {
  return { type: UPLOAD_FIN_WHITE_LIST_CSV_RESPONSE_SUCCESS, resp };
}

function uploadCSVFileForFinWhiteList(groupId, fileData) {
  return (dispatch) => {
    return finWhiteListService
      .uploadCSVFileForFinWhiteList(groupId, fileData)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(finWhiteListCSVResponseSucess(resp));
          // Success Full CSV File uploaded
          toast.success(<NotificationMessage message={resp?.data?.message} />);
        }
      });
  };
}

function finWhiteListStatusSuccess(resp) {
  return {
    type: FIN_WHITE_LIST_STATUS_SUCCESS,
    resp,
  };
}

function getFinWhiteListStatusDetails(groupId) {
  return (dispatch) => {
    return finWhiteListService
      .getFinWhiteListStatusDetails(groupId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(finWhiteListStatusSuccess(resp));
        }
      });
  };
}

const deleteCSVFinWhiteList = (groupId) => {
  return () => {
    return finWhiteListService.deleteCSVFinWhiteList(groupId).then((resp) => {
      return resp;
    });
  };
};

function getFinWhiteListTemplateSuccess(resp) {
  return {
    type: GET_FIN_WHITE_LIST_CSV_TEMPLATE_SUCCESS,
    resp,
  };
}

function getFinWhiteListCSV(groupId) {
  return (dispatch) => {
    return finWhiteListService.getFinWhiteListCSV(groupId).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(getFinWhiteListTemplateSuccess(resp));
        return resp;
      }
    });
  };
}

function finWhiteListTenantsSuccess(resp) {
  return {
    type: GET_FIN_WHITE_LIST_TENTANTS_SUCCESS,
    resp,
  };
}

function getFinWhiteTenantsList(aliasName) {
  return (dispatch) => {
    return finWhiteListService
      .getFinWhiteTenantsList(aliasName)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(finWhiteListTenantsSuccess(resp));
          return resp;
        }
      });
  };
}

function updateFinWhiteListTenantsResponseSuccess(resp) {
  return { type: UPLOAD_FIN_WHITE_LIST_CSV_RESPONSE_SUCCESS, resp };
}

function updateFinWhiteTenantsList(groupId, file) {
  return (dispatch) => {
    return finWhiteListService
      .updateFinWhiteTenantsList(groupId, file)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(updateFinWhiteListTenantsResponseSuccess(resp));
          toast.success(<NotificationMessage message={resp?.data?.message} />);
        }
      });
  };
}

export const finWhiteListActions = {
  // Functions
  finWhiteListCSVResponseSucess,
  finWhiteListStatusSuccess,
  getFinWhiteListTemplateSuccess,
  finWhiteListTenantsSuccess,
  updateFinWhiteListTenantsResponseSuccess,

  // Actions
  uploadCSVFileForFinWhiteList,
  getFinWhiteListStatusDetails,
  deleteCSVFinWhiteList,
  getFinWhiteListCSV,

  getFinWhiteTenantsList,
  updateFinWhiteTenantsList,
};
