import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

// Actions
import { manualRequestFormActions } from "../../store/actions/vorgange/manualRequestFormActions";

// Components
import ErrorText from "../shared/ErrorText";

// Helper
import { formConstants } from "../../helpers/vorgange";

// Constants
import {
  GeschlechtOptions,
  halterRequestFormConstants,
  steuerzahlweiseOptions,
} from "../../constants/ManualRequestFormConstants";
// Core Component
import DownloadFileIcon from "../iconComponents/DownloadFileIcon";
import VollmachtsRelevantIcon from "../core/VollmachtsRelevantIcon";
import VollmachtLoschen from "./inner_components/VollmachtLoschen";
import MDCCheckbox from "../core/MDCCheckbox";
import MDCText from "../core/MDCText";
import MDCLabel from "../core/MDCLabel";

import { DuplicateTextField } from "./DuplicateControls";

import MDCSelect from "../core/MDCSelect";
import MDCSelectWithTooltip from "../core/MDCSelectWithTooltip";
import MDCGroupedDropdown from "../core/MDCGroupedDropdown";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Link } from "@mui/material";
import RepresentativeInfo from "./inner_components/RepresentativeInfo";
import { alertCSS, alertInfoCSS } from "../shared/AlertCSS";
import theme from "../../themes/mdc-theme";
import ZB1ZB2Addresses from "./inner_components/ZB1ZB2Addresses";

const styles = () => ({
  alert: alertCSS,
  alertInfo: alertInfoCSS,
  formBackGround: {
    padding: "0 3%",
    paddingTop: "2%",
    marginTop: "2% !important",
    background: `${theme.palette.MDCColors.white} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.MDCColors.color18}`,
    borderRadius: "5px",
    opacity: 1,
    color: theme.palette.MDCColors.textColor,
    fontSize: "1rem",
    fontFamily: theme.palette.typography.fontFamily,
    lineHeight: 1,
  },
  detailViewBackground: {
    boxShadow: "none",
    paddingTop: "initial",
  },
  vollmachtCompleted: {
    paddingTop: "1%",
  },
  columnTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: theme.palette.typography.fontFamily,
    marginBottom: "1.25rem",
  },
  fieldGroup: {
    marginBottom: ".5rem !important",
  },
  linkLabel: {
    color: theme.palette.MDCColors.color23,
    cursor: "pointer",
  },
  infoMessage: {
    margin: "1.5rem auto",
  },
  downloadFileIconStyles: {
    color: theme.palette.MDCColors.color23,
    marginRight: "0.7rem",
    marginBottom: "0.34rem",
  },
  linkStyles: {
    fontWeight: "bold !important",
  },
});
class HalterRequestForm extends Component {
  getInfoMessage = (message, classes) => {
    const vorgangsart = this.props.vorgangsart.value;

    return (
      !this.props.isDetailDialog &&
      this.props.personenform.value === formConstants.individualPerson &&
      ["NZ", "TZ", "UM", "HA", "WZ"].includes(vorgangsart) && (
        <div className={classes.infoMessage}>
          <div className={`${classes.alert} ${classes.alertInfo}`}>
            <span>{message}</span>
          </div>
        </div>
      )
    );
  };
  checkDisabilityForBankDetails = () => {
    const isSteuerbefreiungPresent = this.props?.formValues?.steuerbefreiung
      ?.value
      ? true
      : false;

    if (
      (this.props.formValues.Vorhanden.value &&
        this.props.personenform.value !== "naturliche") ||
      isSteuerbefreiungPresent ||
      this.props.readOnly ||
      this.props.isVollmachtCompleted
    ) {
      return true;
    }
  };
  getHalterAddressTypeOptions = () => {
    const options = [
      {
        label: this.props?.companyDetails?.name,
        value: this.props?.companyDetails?.name,
      }, // LoggedIn User/Tenant Option
      {
        label: "Individuelle Adresse eingeben",
        value: "individuelleAddresse",
      }, //Individual Address Option
    ];
    this.props?.halterFormOperationIds?.map((operationIdDetails) => {
      options.push({
        label: `${operationIdDetails.operationName.value},${operationIdDetails.operationId.value}`,
        value: operationIdDetails.operationId.value,
      }); // Operations Ids Options assigned to that Tenant
    });
    return options;
  };
  // Get File Name For Document
  getFileNameFromHttpResponse = (httpResponse) => {
    return (httpResponse && httpResponse.headers["x-suggested-filename"]) || "";
  };

  handleBlobData = (fileData, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([fileData], { type: "application/pdf" })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  downloadWirtschaftsTemplate = () => {
    this.setState({ anchorProfile: null });
    let fileName, fileData;

    this.props.downloadWirtschaftsPDF().then(() => {
      if (this.props?.wirtschaftsPDF?.status === 200) {
        fileName = this.getFileNameFromHttpResponse(this.props?.wirtschaftsPDF);
        fileData = this.props?.wirtschaftsPDF?.data;
        this.handleBlobData(fileData, fileName);
      }
    });
  };

  getAutoCompleteDropDownValue = (value, optionsArray) => {
    if (value && optionsArray?.length) {
      let dropdownValue = optionsArray?.filter((item) => item?.id === value); //To get the exact object value for AutoCompleteDropdown
      return dropdownValue[0];
    }
  };
  getQESRepresentativeInfoData = () => {
    // We are getting the info in single string separated by "\n" from Back-end so converting it into array list of person data
    if (this.props?.representativeInfo?.length) {
      const representativeArray = this.props.representativeInfo.split("\n");
      return representativeArray;
    }
    return [];
  };
  render() {
    const vorgangsart = this.props?.vorgangsart?.value;
    const isSteuerbefreiungPresent = this.props?.formValues?.steuerbefreiung
      ?.value
      ? true
      : false;
    const isNZRequest = vorgangsart === "NZ";
    const isTZRequest = vorgangsart === "TZ";
    const isSORequest = vorgangsart === "SO";
    const isReRegistrationRequest = ["UM", "WZ", "HA"].includes(vorgangsart); // For ReRegistration either complex(UM) or simple(WZ)

    const isIndividualPerson =
      this.props.personenform.value === formConstants.individualPerson;

    const isJuristichePerson =
      this.props.personenform.value === formConstants.companyPerson;

    const nzAndNaturliche =
      isIndividualPerson &&
      (isNZRequest || isTZRequest || isReRegistrationRequest || isSORequest);

    const isNzAndNaturlicheReadOnly = nzAndNaturliche;

    const showNameAndAddress =
      nzAndNaturliche &&
      (this.props?.qesCompletedStage === "sign" ||
        this.props?.qesCompletedStage === "ident"); //Only show Name and Address if QES is Signed/ident

    const { classes } = this.props;

    const steuerzahlweiseDisable = true;

    const anschriftView = (
      <div>
        <label className={classes.columnTitle}>
          Anschrift
          {this.props.isQESIconDisplay && (
            <VollmachtsRelevantIcon
              isQESCompleted={this.props.isVollmachtCompleted}
            />
          )}
        </label>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.fieldGroup}>
            <MDCLabel label="Straße" />
            <MDCText
              name="Strasse"
              value={this.props.formValues.Strasse.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              readOnly={
                this.props.readOnly ||
                isNzAndNaturlicheReadOnly ||
                this.props?.isVollmachtCompleted
              }
              error={
                this.props.formValues?.Strasse?.error.length > 0 &&
                !this.props.readOnly
              }
              maxLength={40}
            />

            {this.props.formValues.Strasse.hasOwnProperty("duplicate") && (
              <DuplicateTextField
                name="Strasse"
                value={this.props.formValues.Strasse.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() => this.props.resolveConflict("Strasse", true)}
                onReject={() => this.props.resolveConflict("Strasse", false)}
                maxLength={40}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="strasse-error"
                error={this.props.formValues.Strasse.error}
              />
            )}
          </Grid>

          <Grid item xs={6} className={classes.fieldGroup}>
            <MDCLabel label="Nummer" />
            <MDCText
              name="Hausnummer"
              value={this.props.formValues.Hausnummer.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              inputProps={{ maxLength: 25 }}
              readOnly={
                this.props.readOnly ||
                isNzAndNaturlicheReadOnly ||
                this.props?.isVollmachtCompleted
              }
              error={
                this.props.formValues?.Hausnummer?.error.length > 0 &&
                !this.props.readOnly
              }
              isToolTipText={
                this.props.readOnly || isNzAndNaturlicheReadOnly
                  ? this.props.formValues.Hausnummer.value
                  : ""
              }
              maxLength={25}
            />

            {this.props.formValues.Hausnummer.hasOwnProperty("duplicate") && (
              <DuplicateTextField
                name="Hausnummer"
                value={this.props.formValues.Hausnummer.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() => this.props.resolveConflict("Hausnummer", true)}
                onReject={() => this.props.resolveConflict("Hausnummer", false)}
                maxLength={25}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="Hausnummer-error"
                error={this.props.formValues.Hausnummer.error}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.fieldGroup}>
            <MDCLabel label="Postleitzahl" />
            <MDCText
              name="Postleitzahl"
              value={this.props.formValues.Postleitzahl.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              readOnly={
                this.props.readOnly ||
                isNzAndNaturlicheReadOnly ||
                this.props?.isVollmachtCompleted
              }
              error={
                this.props.formValues?.Postleitzahl?.error.length > 0 &&
                !this.props.readOnly
              }
              maxLength={10}
            />

            {this.props.formValues.Postleitzahl.hasOwnProperty("duplicate") && (
              <DuplicateTextField
                name="Postleitzahl"
                value={this.props.formValues.Postleitzahl.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() =>
                  this.props.resolveConflict("Postleitzahl", true)
                }
                onReject={() =>
                  this.props.resolveConflict("Postleitzahl", false)
                }
                maxLength={10}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="postleitzahl-error"
                error={this.props.formValues.Postleitzahl.error}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.fieldGroup}>
            <MDCLabel label="Stadt" />
            <MDCText
              name="Wohnort"
              value={this.props.formValues.Wohnort.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              readOnly={
                this.props.readOnly ||
                isNzAndNaturlicheReadOnly ||
                this.props?.isVollmachtCompleted
              }
              error={
                this.props.formValues?.Wohnort?.error.length > 0 &&
                !this.props.readOnly
              }
              maxLength={40}
            />

            {this.props.formValues.Wohnort.hasOwnProperty("duplicate") && (
              <DuplicateTextField
                name="Wohnort"
                value={this.props.formValues.Wohnort.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() => this.props.resolveConflict("Wohnort", true)}
                onReject={() => this.props.resolveConflict("Wohnort", false)}
                maxLength={40}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="wohnort-error"
                error={this.props.formValues.Wohnort.error}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
    const steuermandatview = (
      <Grid item xs={12}>
        <div>
          <label className={classes.columnTitle}>Steuermandat</label>
          <Grid container>
            <Grid item xs={12} className={classes.fieldGroup}>
              <Grid marginTop={".3rem"}>
                <MDCLabel label="Steuerbefreiung" />
                <MDCSelectWithTooltip
                  name="steuerbefreiung"
                  placeholder="keine"
                  value={this.props?.formValues?.steuerbefreiung?.value}
                  handleChange={(e) => this.props.handleChange(e, 3)}
                  disabled={
                    this.props.readOnly || this.props.isVollmachtCompleted
                  }
                  error={
                    this.props.formValues?.steuerbefreiung?.error &&
                    !this.props.readOnly
                  }
                  options={this.props?.steuerbefreiungData?.data}
                />
                {!this.props.readOnly && (
                  <ErrorText
                    id="steuerbefreiung-error"
                    error={this.props.formValues?.steuerbefreiung?.error}
                  />
                )}
              </Grid>
            </Grid>
            {!isSteuerbefreiungPresent && (
              <Grid item xs={12} className={classes.fieldGroup}>
                <Grid marginTop={".3rem"}>
                  <MDCLabel label="Zahlweise" />
                  <MDCSelect
                    name="steuerzahlweise"
                    value="jährlich"
                    handleChange={(e) => this.props.handleChange(e, 3)}
                    //for time being will always remain disabled with no error
                    disabled={
                      steuerzahlweiseDisable ||
                      this.props.readOnly ||
                      this.props.isVollmachtCompleted
                    }
                    // error={
                    //   this.props.formValues.steuerzahlweise.error &&
                    //   !this.props.readOnly
                    // }
                    options={steuerzahlweiseOptions}
                  />
                  {this.props.formValues.steuerzahlweise.hasOwnProperty(
                    "duplicate"
                  ) && (
                    <>
                      <DuplicateTextField
                        name="steuerzahlweise"
                        value={this.props.formValues.steuerzahlweise.duplicate}
                        readOnly
                        step={this.props.step}
                        onAccept={() =>
                          this.props.resolveConflict("steuerzahlweise", true)
                        }
                        onReject={() =>
                          this.props.resolveConflict("steuerzahlweise", false)
                        }
                      />
                    </>
                  )}
                  {/* {!this.props.readOnly && (
                    <ErrorText
                      id="steuerzahlweise-error"
                      error={this.props.formValues.steuerzahlweise.error}
                    />
                  )} */}
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    );
    const bankDetailsView = (
      <Grid>
        <label className={classes.columnTitle}>
          Bankverbindung Lastschrift Kfz-Steuer
          {this.props.isQESIconDisplay && (
            <VollmachtsRelevantIcon
              isQESCompleted={this.props.isVollmachtCompleted}
            />
          )}
        </label>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.fieldGroup}>
            <MDCLabel label="Kontoinhaber" />
            <MDCText
              name="kontoinhaber"
              value={this.props.formValues.kontoinhaber.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              readOnly={this.checkDisabilityForBankDetails()}
              error={
                this.props.formValues?.kontoinhaber?.error.length > 0 &&
                !this.props.readOnly
              }
              maxLength={70}
            />

            {this.props.formValues.kontoinhaber.hasOwnProperty("duplicate") && (
              <DuplicateTextField
                name="kontoinhaber"
                value={this.props.formValues.kontoinhaber.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() =>
                  this.props.resolveConflict("kontoinhaber", true)
                }
                onReject={() =>
                  this.props.resolveConflict("kontoinhaber", false)
                }
                maxLength={70}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="kontoinhaber-error"
                error={this.props.formValues.kontoinhaber.error}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.fieldGroup}>
            <MDCLabel label="Kreditinstitut" />
            <MDCText
              name="nameBankverbindung"
              value={this.props.formValues.nameBankverbindung.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              readOnly={this.checkDisabilityForBankDetails()}
              error={
                this.props.formValues?.nameBankverbindung?.error.length > 0 &&
                !this.props.readOnly
              }
              maxLength={255}
            />

            {this.props.formValues.nameBankverbindung.hasOwnProperty(
              "duplicate"
            ) && (
              <DuplicateTextField
                name="nameBankverbindung"
                value={this.props.formValues.nameBankverbindung.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() =>
                  this.props.resolveConflict("nameBankverbindung", true)
                }
                onReject={() =>
                  this.props.resolveConflict("nameBankverbindung", false)
                }
                maxLength={255}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="nameBankverbindung-error"
                error={this.props.formValues.nameBankverbindung.error}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.fieldGroup}>
            <MDCLabel label="IBAN" />
            <MDCText
              name="iban"
              value={this.props.formValues.iban.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              readOnly={this.checkDisabilityForBankDetails()}
              error={
                this.props.formValues?.iban?.error.length > 0 &&
                !this.props.readOnly
              }
              maxLength={34}
            />

            {this.props.formValues.iban.hasOwnProperty("duplicate") && (
              <DuplicateTextField
                name="nameBankverbindung"
                value={this.props.formValues.iban.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() => this.props.resolveConflict("iban", true)}
                onReject={() => this.props.resolveConflict("iban", false)}
                maxLength={34}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="iban-error"
                error={this.props.formValues.iban.error}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.fieldGroup}>
            <MDCLabel label="BIC" />
            <MDCText
              name="bic"
              value={this.props.formValues.bic.value}
              onChange={(e) => this.props.handleChange(e, 3)}
              readOnly={this.checkDisabilityForBankDetails()}
              error={
                this.props.formValues?.bic?.error.length > 0 &&
                !this.props.readOnly
              }
              maxLength={11}
            />

            {this.props.formValues.bic.hasOwnProperty("duplicate") && (
              <DuplicateTextField
                name="bic"
                value={this.props.formValues.bic.duplicate}
                readOnly
                step={this.props.step}
                onAccept={() => this.props.resolveConflict("bic", true)}
                onReject={() => this.props.resolveConflict("bic", false)}
                maxLength={11}
              />
            )}
            {!this.props.readOnly && (
              <ErrorText
                id="bic-error"
                error={this.props.formValues.bic.error}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );

    const ZB1ZB2View = (
      <Grid item xs={12} marginTop={"1rem"}>
        <ZB1ZB2Addresses
          formValues={this.props.formValues}
          handleChange={this.props.handleChange}
          operationIdsOptions={this.props.operationIdsOptions}
          readOnly={this.props.readOnly}
          vorgangsart={this.props.vorgangsart}
          isVollmachtCompleted={this.props.isVollmachtCompleted}
          setZB1AddressInZB2={this.props.setZB1AddressInZB2}
          isZustellungZBError={this.props.isZustellungZBError}
          isDetailDialog={this.props.isDetailDialog}
          isQESIconDisplay={this.props.isQESIconDisplay}
          requestId={this.props?.requestId}
        />
      </Grid>
    );
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          className={`${classes.formBackGround} ${
            this.props.isDetailDialog ? classes.detailViewBackground : ""
          } ${
            this.props?.isVollmachtCompleted ? classes.vollmachtCompleted : ""
          }`}
        >
          {!this.props?.isDetailDialog && this.props?.isVollmachtCompleted && (
            <VollmachtLoschen
              handleModal={this.props.handleVollmachtLoschenChange}
              createNewRequest={this.props?.createNewRequest}
            />
          )}

          <div>
            {!this.props.isDetailDialog && (
              <>{this.props.selectDuplicateValues()}</>
            )}

            {!this.props.readOnly &&
              (this.props.vollmachtTabStatus ||
                !this.props.qesCompletedStage) &&
              this.getInfoMessage(
                halterRequestFormConstants.ADDRESS_FIELDS_BLANK_INFO,
                classes
              )}

            <Grid container spacing={3} marginBottom="1%">
              {isJuristichePerson && (
                <Grid item xs={4}>
                  <label className={classes.columnTitle}>Halter*in</label>
                  <>
                    <Grid item xs={12} className={classes.fieldGroup}>
                      <MDCLabel label="Name der juristischen Person" />
                      <MDCSelect
                        value={this.props?.formValues?.halterAddress?.value}
                        name="halterAddress"
                        handleChange={(e) => this.props.handleChange(e, 3)}
                        disabled={
                          this.props?.isVollmachtCompleted ||
                          this.props?.readOnly
                        }
                        error={
                          this.props.formValues.halterAddress.error &&
                          !this.props.readOnly
                        }
                        options={this.getHalterAddressTypeOptions()}
                        placeholder={"Bitte auswählen"}
                      />

                      <ErrorText
                        id="halterAddress-error"
                        error={this.props.formValues.halterAddress?.error}
                      />
                    </Grid>
                    <Grid item xs={12} marginY="1.5rem">
                      <Grid container justifyContent={"space-between"}>
                        <Grid item alignSelf={"end"}>
                          <MDCLabel label="Wirtschaftszweig" />
                        </Grid>
                        <Grid item padding="0 !important">
                          <Link
                            className={this.props?.classes?.linkStyles}
                            component="button"
                            color="inherit"
                            variant="body1"
                            onClick={() => this.downloadWirtschaftsTemplate()}
                          >
                            <DownloadFileIcon
                              className={
                                this.props?.classes?.downloadFileIconStyles
                              }
                            />
                            Wirtschaftszweigübersicht.pdf
                          </Link>
                        </Grid>
                      </Grid>

                      <MDCGroupedDropdown
                        error={
                          this.props.formValues?.SchluesselBeruf?.error &&
                          !this.props.readOnly
                        }
                        options={this.props?.wirtschaftsData?.data}
                        value={this.getAutoCompleteDropDownValue(
                          this.props.formValues?.SchluesselBeruf?.value,
                          this.props?.wirtschaftsData?.data
                        )}
                        disabled={
                          this.props?.isVollmachtCompleted ||
                          this.props?.readOnly
                        }
                        onChange={(val) => {
                          let event = {
                            target: {
                              name: "SchluesselBeruf",
                              value: val,
                            },
                          };
                          this.props.handleChange(event, 3);
                        }}
                        placeholder={"Bitte auswählen"}
                      />
                      <ErrorText
                        id="SchluesselBeruf-error"
                        error={this.props.formValues?.SchluesselBeruf?.error}
                      />
                    </Grid>
                    <Grid item xs={12} marginY="1rem">
                      {this.props.formValues.halterAddress.value ===
                        "individuelleAddresse" && (
                        <>
                          <MDCLabel label="Unternehmensname" />
                          <MDCText
                            name="NameJuristischePerson"
                            value={
                              this.props.formValues.NameJuristischePerson.value
                            }
                            onChange={(e) => this.props.handleChange(e, 3)}
                            readOnly={
                              this.props.readOnly ||
                              this.props?.isVollmachtCompleted
                            }
                            error={
                              this.props.formValues?.NameJuristischePerson
                                ?.error.length > 0
                            }
                            maxLength={150}
                          />
                          <ErrorText
                            id="nameJuristischePerson-error"
                            error={
                              this.props.formValues.NameJuristischePerson.error
                            }
                          />
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      marginTop={
                        this.props.formValues.halterAddress.value ===
                        "individuelleAddresse"
                          ? "1.75rem"
                          : "6.8rem"
                      }
                    >
                      <Grid marginBottom={".5rem"}>{anschriftView}</Grid>
                    </Grid>
                  </>
                </Grid>
              )}
              {nzAndNaturliche && (
                <Grid item xs={4}>
                  <div>
                    <label className={classes.columnTitle}>
                      Halter*in
                      {this.props.isQESIconDisplay && (
                        <VollmachtsRelevantIcon
                          isQESCompleted={this.props.isVollmachtCompleted}
                        />
                      )}
                    </label>
                    <Grid container spacing={0.5}>
                      {this.props.personenform.value ===
                        formConstants.individualPerson && (
                        <>
                          <Grid item xs={6} className={classes.fieldGroup}>
                            <MDCLabel label="Vorname" />
                            <MDCText
                              name="vorname"
                              value={this.props.formValues.vorname.value}
                              onChange={(e) => this.props.handleChange(e, 3)}
                              readOnly={
                                this.props.readOnly || isNzAndNaturlicheReadOnly
                              }
                              error={
                                this.props.formValues?.vorname?.error.length > 0
                              }
                              maxLength={60}
                            />
                            <ErrorText
                              id="vorname-error"
                              error={this.props.formValues.vorname.error}
                            />
                          </Grid>

                          <Grid item xs={6} className={classes.fieldGroup}>
                            <MDCLabel label="Name" />
                            <MDCText
                              name="auskunft_NatuerlichePerson_FamiliennameNachname"
                              value={
                                this.props?.formValues
                                  ?.auskunft_NatuerlichePerson_FamiliennameNachname
                                  ?.value
                              }
                              onChange={(e) => this.props.handleChange(e, 3)}
                              readOnly={
                                this.props.readOnly || isNzAndNaturlicheReadOnly
                              }
                              error={
                                this.props?.formValues
                                  ?.auskunft_NatuerlichePerson_FamiliennameNachname
                                  ?.error.length > 0
                              }
                              maxLength={45}
                            />
                            <ErrorText
                              id="name-error"
                              error={
                                this.props?.formValues
                                  ?.auskunft_NatuerlichePerson_FamiliennameNachname
                                  ?.error
                              }
                            />
                          </Grid>

                          {nzAndNaturliche && (
                            <>
                              <Grid item xs={12} className={classes.fieldGroup}>
                                <MDCLabel label="Geburtsname" />
                                <MDCText
                                  name="geburtsnameNachname"
                                  value={
                                    this.props.formValues.geburtsnameNachname
                                      .value
                                  }
                                  onChange={(e) =>
                                    this.props.handleChange(e, 3)
                                  }
                                  readOnly={
                                    this.props.readOnly ||
                                    isNzAndNaturlicheReadOnly
                                  }
                                  error={
                                    this.props.formValues?.geburtsnameNachname
                                      ?.error.length > 0
                                  }
                                  maxLength={45}
                                />
                                <ErrorText
                                  id="vorname-error"
                                  error={
                                    this.props.formValues?.geburtsnameNachname
                                      ?.error
                                  }
                                />
                              </Grid>

                              <Grid item xs={6} className={classes.fieldGroup}>
                                <div>
                                  <MDCLabel label="Geschlecht" />
                                  {this.props.isQESIconDisplay && (
                                    <VollmachtsRelevantIcon
                                      isQESCompleted={
                                        this.props.isVollmachtCompleted
                                      }
                                    />
                                  )}
                                </div>

                                <MDCSelect
                                  name="geschlecht"
                                  value={this.props.formValues.geschlecht.value}
                                  handleChange={(e) =>
                                    this.props.handleChange(e, 3)
                                  }
                                  disabled={
                                    this.props.readOnly ||
                                    this.props?.isVollmachtCompleted
                                  }
                                  error={
                                    this.props.formValues?.geschlecht?.error
                                      .length > 0
                                  }
                                  options={GeschlechtOptions}
                                  isSmall
                                />
                                <ErrorText
                                  id="vorname-error"
                                  error={this.props.formValues.geschlecht.error}
                                />
                              </Grid>

                              <Grid item xs={6} className={classes.fieldGroup}>
                                <MDCLabel label="Geburtsdatum" />
                                <MDCText
                                  name="geburtstag"
                                  value={
                                    this.props.formValues.geburtstag.value &&
                                    moment(
                                      this.props.formValues.geburtstag.value,
                                      "YYYYMMDD"
                                    ).format("DD.MM.YYYY")
                                  }
                                  onChange={(e) =>
                                    this.props.handleChange(e, 3)
                                  }
                                  readOnly={
                                    this.props.readOnly ||
                                    isNzAndNaturlicheReadOnly
                                  }
                                  error={
                                    this.props.formValues?.geburtstag?.error
                                      .length > 0
                                  }
                                  maxLength={8}
                                />
                                <ErrorText
                                  id="vorname-error"
                                  error={this.props.formValues.geburtstag.error}
                                />
                              </Grid>
                              <Grid item xs={12} className={classes.fieldGroup}>
                                <MDCLabel label="Geburtsort" />
                                <MDCText
                                  name="geburtsort"
                                  value={this.props.formValues.geburtsort.value}
                                  onChange={(e) =>
                                    this.props.handleChange(e, 3)
                                  }
                                  readOnly={
                                    this.props.readOnly ||
                                    isNzAndNaturlicheReadOnly
                                  }
                                  error={
                                    this.props.formValues?.geburtsort?.error
                                      .length > 0
                                  }
                                  maxLength={40}
                                />
                                <ErrorText
                                  id="vorname-error"
                                  error={this.props.formValues.geburtsort.error}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </div>
                </Grid>
              )}

              <Grid item xs={4}>
                <div>
                  {showNameAndAddress && <>{anschriftView}</>}
                  {/* show anchrift View in 2nd Column if its a QES completed request for NP**/}
                  {(isNZRequest ||
                    isTZRequest ||
                    isSORequest ||
                    isReRegistrationRequest) &&
                    this.props.personenform.value ===
                      formConstants.individualPerson && (
                      <>
                        <Grid
                          marginTop={this.props?.isDetailDialog ? "1rem" : ""}
                        >
                          {steuermandatview}
                        </Grid>
                        {ZB1ZB2View}
                      </>
                    )}

                  {isJuristichePerson && (
                    <>
                      <label className={classes.columnTitle}>
                        Zollgroßkundennummer
                      </label>
                      <Grid
                        marginY={
                          this.props.isDetailDialog ? "1.3rem" : "1.75rem"
                        }
                      >
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCCheckbox
                            name="Vorhanden"
                            label="Zollgroßkundennummer vorhanden"
                            checked={this.props.formValues.Vorhanden.value}
                            onChange={(e) => this.props.handleChange(e, 3)}
                            disabled={
                              this.props.readOnly ||
                              this.props?.isVollmachtCompleted
                            }
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="Zollgroßkundennummer des Halters" />
                          <MDCText
                            name="kundennummerdeshalters"
                            value={
                              this.props.formValues.kundennummerdeshalters.value
                            }
                            onChange={(e) => this.props.handleChange(e, 3)}
                            readOnly={
                              this.props.readOnly ||
                              !this.props.formValues.Vorhanden.value ||
                              this.props?.isVollmachtCompleted
                            }
                            error={
                              this.props.formValues?.kundennummerdeshalters
                                ?.error.length > 0
                            }
                            maxLength={8}
                          />
                          <ErrorText
                            id="nameJuristischePerson-error"
                            error={
                              this.props.formValues.kundennummerdeshalters.error
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} marginTop="2rem">
                        {bankDetailsView}
                      </Grid>
                    </>
                  )}
                </div>
              </Grid>

              {(isNZRequest ||
                isTZRequest ||
                isSORequest ||
                isReRegistrationRequest) && (
                <Grid item xs={4}>
                  {this.props.personenform.value !== "naturliche" && (
                    <>
                      <Grid>{steuermandatview}</Grid>
                      {this.props?.isVollmachtCompleted && (
                        <Grid marginTop={"1rem"}>
                          <label className={classes.columnTitle}>
                            Gesetztlicher Vertreter
                          </label>
                          <RepresentativeInfo
                            personData={this.getQESRepresentativeInfoData()}
                          />
                        </Grid>
                      )}
                      {ZB1ZB2View}
                    </>
                  )}
                  {isIndividualPerson && <>{bankDetailsView}</>}
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    wirtschaftsPDF: state.manualRequestFormReducer.wirtschaftsPDF,
    wirtschaftsData: state.manualRequestFormReducer.wirtschaftsData,
    steuerbefreiungData: state.manualRequestFormReducer.steuerbefreiungData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadWirtschaftsPDF: () =>
      dispatch(manualRequestFormActions.downloadWirtschaftsPDF()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HalterRequestForm));
