import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

// Theme
import theme from "../../themes/mdc-theme";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: 0,
    fontSize: "1.5rem",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    "&.MuiStepIcon-root.MuiStepIcon-active": {
      color: "red",
    },
  },
  customLabelStyle: {
    fontSize: "1.5rem !important",
  },
  icon: {
    "&..MuiStepLabel-label": {
      fontSize: "1.5rem !important",
    },
    color: theme.palette.MDCColors?.color18,
    "&$activeIcon": {
      color: theme.palette.MDCColors?.color18,
    },
    "&$completedIcon": {
      color: theme.palette.MDCColors?.color23,
    },

    "&.MuiStepIcon-text": {
      color: theme.palette.MDCColors?.black,
    },
  },
  activeIcon: {},
  completedIcon: {},
}));

function getSteps() {
  return [1, 2, 3];
}

export default function MDCStepper({ step }) {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper activeStep={step} className={classes.stepper}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                classes={{label: classes.customLabelStyle}}
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                    active: classes.activeIcon,
                    completed: classes.completedIcon,
                  },
                }}
              />
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
