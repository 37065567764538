import { combineReducers } from "redux";

import authentication from "./authenticationReducer";
import registration from "./registrationReducer";

import adminInformation from "./admin/adminReducer";
import vorgangeListReducer from "./vorgange/vorgangeListReducer";

// Dashboard
import dashboardReducer from "./dashboard/dashboardReducer";
import appLoginDashboardReducer from "./appLoginDashboard/appLoginDashboardReducer";

// Archive Statistics
import archiveStatisticsReducer from "./dashboard/archiveStatisticsReducer";

import csvImportExportReducer from "./exportImportFile/csvImportExportReducer";
import vorgangeDetailsReducer from "./vorgange/vorgangeDetailsReducer";
import manualRequestFormReducer from "./vorgange/manualRequestFormReducer";
import verimiAdminReducer from "./admin/verimiAdminReducer";
import notificationReducer from "./notification/notificationReducer";

// KBA Fee Report
import feesReportListReducer from "./admin/KBA/feesReportListReducer";

// KBA Fee Report CSV
import feesReportCsvListReducer from "./admin/KBA/feesReportCsvListReducer";

// Ordered License PLate
import orderLicensePlateReducer from "./vorgange/orderLicensePlateReducer";

// Requested XML Logs
import requestedXmlLogsReducer from "./admin/requestedXmlLogsReducer";

// POA
import vollmachtDatenBankReducer from "./vollmachtDatenBankReducer";

// Requested XML Logs
import deadLetterQueueReducer from "./admin/deadLetterQueueReducer";

// Fin White List
import finWhiteListReducer from "./appLoginDashboard/finWhiteListReducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  adminInformation,
  vorgangeListReducer,
  dashboardReducer,
  appLoginDashboardReducer,
  archiveStatistics: archiveStatisticsReducer,
  csvImportExportReducer,
  vorgangeDetailsReducer,
  manualRequestFormReducer,
  verimiAdminReducer,
  notificationReducer,
  feesReportListReducer,
  feesReportCsvListReducer,
  orderLicensePlateReducer,
  requestedXmlLogsReducer,
  vollmachtDatenBankReducer,
  deadLetterQueueReducer,
  finWhiteListReducer,
});

export default rootReducer;
