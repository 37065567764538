// Constants
import {
  FIN_WHITE_LIST_STATUS_SUCCESS,
  GET_FIN_WHITE_LIST_CSV_TEMPLATE_SUCCESS,
  GET_FIN_WHITE_LIST_TENTANTS_SUCCESS,
  UPLOAD_FIN_WHITE_LIST_CSV_RESPONSE_SUCCESS,
} from "../../../constants/FinwhiteListConstants";

const initialState = {};

const finWhiteListReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case UPLOAD_FIN_WHITE_LIST_CSV_RESPONSE_SUCCESS:
      return Object.assign({}, { data: action.resp.data });
    case FIN_WHITE_LIST_STATUS_SUCCESS:
      return Object.assign({}, state, {
        finWhiteListStatusDetails: action.resp.data,
      });
    case GET_FIN_WHITE_LIST_CSV_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        finWhiteListCSVData: action.resp,
      });
    case GET_FIN_WHITE_LIST_TENTANTS_SUCCESS:
      return Object.assign({}, state, {
        finWhiteTenantsListData: action.resp,
      });
    default:
      return state;
  }
};

export default finWhiteListReducer;
