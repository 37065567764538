// Service
import { appLoginDashboardService } from "../../services/appLoginDashboard/appLoginDashboardService";

// Constants
import {
  GET_TENANT_TABLE_DATA_SUCCESS,
  GET_TECHNICAL_ALIAS_SUCCESS,
  GET_TENTANT_GROUPS_SUCCESS,
  GET_FEE_REPORTING_TABLE_DATA_SUCCESS,
  GET_TENANTS_REQUEST_TYPES_SUCCESS,
  CREATE_TENANTS_STATUS,
} from "../../../constants/DashboardConstants";
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";
import NotificationMessage from "../../../components/core/NotificationMessage";
import { toast } from "react-toastify";

function tenantTableDataSuccess(resp) {
  return { type: GET_TENANT_TABLE_DATA_SUCCESS, resp };
}
/**
 * Get tenant table Details
 *
 * @param {*}
 * @returns
 */
function getTenantTableData(query, groupId) {
  return (dispatch) => {
    return appLoginDashboardService
      .getTenantTableData(query, groupId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(tenantTableDataSuccess(resp.data));
        }
        return resp;
      });
  };
}

function technicalAliasDetailsSuccess(resp) {
  return { type: GET_TECHNICAL_ALIAS_SUCCESS, resp };
}
/**
 * Get technical alias and its Details
 *
 * @param {*}
 * @returns
 */
function getTechnicalAliasDetails(tenantName) {
  return (dispatch) => {
    return appLoginDashboardService
      .getTechnicalAliasDetails(tenantName)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(technicalAliasDetailsSuccess(resp));
        }
        return resp;
      });
  };
}

/**
 * Accept technical alias value
 *
 * @param {*}
 * @returns
 */
function acceptTechnicalAlias(tenantData) {
  return () => {
    return appLoginDashboardService
      .acceptTechnicalAlias(tenantData)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          return resp;
        }
      });
  };
}

/**
 * Reject technical alias value
 *
 * @param {*}
 * @returns
 */
function rejectTechnicalAlias(tenantName) {
  return () => {
    return appLoginDashboardService
      .rejectTechnicalAlias(tenantName)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          return resp;
        }
      });
  };
}

/**
 * Exporting tenant Data in CSV
 *
 * @param {*}
 * @returns
 */
function exportTenantData(tenantDetails) {
  return () => {
    return appLoginDashboardService
      .exportTenantData(tenantDetails)
      .then((resp) => {
        return resp;
      });
  };
}

function tenantGroupsSuccess(resp) {
  return { type: GET_TENTANT_GROUPS_SUCCESS, resp };
}
/**
 * Get Tenant Groups
 *
 * @param {*}
 * @returns
 */
function getTenantgroups() {
  return (dispatch) => {
    return appLoginDashboardService.getTenantgroups().then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(tenantGroupsSuccess(resp));
      }
      return resp;
    });
  };
}

function feeReportingTableDataSuccess(resp) {
  return { type: GET_FEE_REPORTING_TABLE_DATA_SUCCESS, resp };
}
/**
 * Get fee Reporting table Details
 *
 * @param {*}
 * @returns
 */
function getfeeReportingTableData(query, requestHeaders) {
  return (dispatch) => {
    return appLoginDashboardService
      .getfeeReportingTableData(query, requestHeaders)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(feeReportingTableDataSuccess(resp.data));
        }
        return resp;
      });
  };
}
/**
 * Get DeadLetter Queue Details
 *
 * @param {*}
 * @returns
 */
function getTenantsDeadletterQueue(tenantdata) {
  return async () => {
    return appLoginDashboardService
      .getTenantsDeadletterQueue(tenantdata)
      .then((resp) => {
        return resp;
      });
  };
}

function getTenantsUnmatchedDeadletterQueue(tenantdata) {
  return async () => {
    return appLoginDashboardService
      .getTenantsUnmatchedDeadletterQueue(tenantdata)
      .then((resp) => {
        return resp;
      });
  };
}

function tenantsRequestTypesSuccess(resp) {
  return { type: GET_TENANTS_REQUEST_TYPES_SUCCESS, resp };
}

/**
 * Get Request Types For Manual Request
 *
 * @param {*}
 * @returns
 */
function getTenantsRequestTypes(tenantsAlias) {
  return async (dispatch) => {
    const resp = await appLoginDashboardService.getTenantsRequestTypes(
      tenantsAlias
    );
    if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
      dispatch(tenantsRequestTypesSuccess(resp.data));
    }
    return resp;
  };
}

/**
 * Create Tenants via CSV Upload
 *
 * @param {*}
 * @returns
 */
function createTenants(groupId, uploadedFileData) {
  return async () => {
    const resp = await appLoginDashboardService.createTenants(
      groupId,
      uploadedFileData
    );
    if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
      // Successfull CSV File uploaded
      toast.success(<NotificationMessage message={resp?.data?.message} />);
    }
    return resp;
  };
}

function getCreateTenantStatusSuccess(resp) {
  return { type: CREATE_TENANTS_STATUS, resp };
}
/**
 * Check Tenants Creation Status
 *
 * @param {*}
 * @returns
 */
function getCreateTenantStatus(groupId) {
  return async (dispatch) => {
    const resp = await appLoginDashboardService.getCreateTenantStatus(groupId);
    if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
      dispatch(getCreateTenantStatusSuccess(resp?.data));
    }
    return resp;
  };
}
export const appLoginDashboardActions = {
  // Added for unit Test Cases
  getTenantTableData,
  tenantTableDataSuccess,
  getTechnicalAliasDetails,
  technicalAliasDetailsSuccess,
  feeReportingTableDataSuccess,
  tenantsRequestTypesSuccess,
  acceptTechnicalAlias,
  rejectTechnicalAlias,
  exportTenantData,
  getTenantgroups,
  tenantGroupsSuccess,
  getfeeReportingTableData,
  getTenantsDeadletterQueue,
  getTenantsRequestTypes,
  getTenantsUnmatchedDeadletterQueue,
  createTenants,
  getCreateTenantStatus,
  getCreateTenantStatusSuccess,
};
