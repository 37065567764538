module.exports = {
  VORGANT_STATUS_FEHLER: "Fehler",
  VORGANT_STATUS_FEHLER_B: "Fehler (B)",
  VORGANT_STATUS_FEHLER_T: "Fehler (T)",
  VORGANT_STATUS_VERSANDFERTIG: "Versandfertig",
  VORGANT_STATUS_GESPEICHERT: "Gespeichert",
  VORGANT_STATUS_ABGELEHNT: "Abgelehnt",
  VORGANT_STATUS_VERWORFEN: "Verworfen",
  VORGANT_STATUS_GENEHMIGT: "Genehmigt",
  VORGANT_STATUS_EINGEREICHT: "Eingereicht",

  STATUSARR: {
    Abgelehnt: "Abgelehnt",
    Verworfen: "Verworfen",
    Genehmigt: "Genehmigt",
    Gespeichert: "Gespeichert",
    Versandfertig: "Versandfertig",
    Eingereicht: "Eingereicht",
    Fehler: "Fehler",
    "Fehler (B)": "Fehler (B)",
    "Fehler (T)": "Fehler (T)",
  },
  GKS_STATUS_ARR: {
    Abgelehnt: "Abgelehnt",
    Genehmigt: "Genehmigt",
  },
  TILES_LABEL: {
    ready: "Versandfertige Anträge",
    antrage: "Anträge",
    cache: "Zwischenspeicher",
  },
  REQUEST_TYPES: ["NZ", "TZ", "AB", "UM", "HA", "WZ", "SO"],
  REQUESTS_TYPE_DISPLAY_NAMES: {
    AB: "Außerbetriebsetzung",
    NZ: "Neuzulassung",
    TZ: "Tageszulassung",
    UM: "Ummeldung",
    HA: "Namens- oder Adressänderung des Halters",
    SO: "Offen",
    WZ: "Wiederzulassung",
  },
  MANDANTEN: "Mandanten",
  HEUTE: "heute", //today
  INSGESAMT: "insgesamt", //overall
  ARCHIV: "Archiv", // archive
  FEES_REPORT: "fees_report", // Fees Report

  dashboardButtonsGroupLabels: [
    {
      buttonLabel: "Aktive Anträge",
      buttonId: "insgesamt",
      toolTipLabel: "",
    },
    // {
    //   buttonLabel: "Archivierte Anträge",
    //   buttonId: "Archiv",
    //   toolTipLabel:
    //     "Im Archiv finden Sie Vorgänge, die mindestens 10 und maximal 180 Tage alt sind. Ältere Beiträge werden unwiderruflich gelöscht.",
    // },
    {
      buttonLabel: "Gebührenreport",
      buttonId: "fees_report",
      toolTipLabel: "",
    },
  ],
  appLoginDashboardTabsLabels: [
    {
      buttonLabel: "Mandantenverwaltung",
      buttonId: "Mandantenverwaltung",
      toolTipLabel: "",
    },
    {
      buttonLabel: "Gebührenreport",
      buttonId: "Gebührenreport",
      toolTipLabel: "",
    },
  ],
  ERROR_MESSAGES: [
    "Die hochzuladende Datei darf nicht größer sein, als 8MB. Bitte wählen Sie eine kleinere Datei aus.",
    "Bitte wählen Sie eine CSV-Datei aus, um diese hochzuladen.",
    "Ihre Anträge wurden erfolgreich in die Vorgangsliste geladen. Sie können diese nun bearbeiten und absenden.", //Information message when request is uploaded.
  ],
  DASH_DOWNLOAD_EXCEL_ERROR:
    "Bitte wählen Sie mindestens einen Eintrag aus, um die Download Funktionalität zu nutzen.",
  READ_RECORD_ERROR:
    "Es tut uns leid! Es ist ein Fehler  bei der Statusabfrage aufgetreten. Bitte versuchen Sie es später noch einmal.",
  DASH_NO_SELECT_ERROR:
    "Bitte wählen Sie mindestens einen Eintrag aus, um die gewünschte Funktionalität zu nutzen.",
  DASH_NO_NEU_ERROR:
    'Bitte wählen Sie einen Vorgang mit dem Status "Versandfertig" aus, um diesen weiterzuleiten.',
  DASH_DUPLICATE_ERROR:
    "Der ausgewählte Antrag enthält noch Aktualisierungen, bitte nehmen Sie diese an oder verwerfen Sie diese, um den Antrag einreichen zu können.",
  DASH_NO_TEIL_ERROR:
    'Bitte wählen Sie einen Vorgang mit dem Status "Eingereicht" aus, um dessen Status abzurufen',
  DASH_NO_NEU_ABLAGE_ERROR:
    'Bitte wählen Sie einen Vorgang mit dem Status "Versandfertig" oder "Gespeichert" aus, um diesen auszusondern.',
  DASH_NO_NEU_EDIT_ERROR:
    'Bitte wählen Sie nur einen Prozess mit dem Status "Versandfertig", "Gespeichert" oder "Fehler" aus, um ihn zu bearbeiten.',
  DASH_ONLY_ONE_NEU_EDIT_ERROR:
    'Bitte wählen Sie nur einen Prozess mit dem Status "Versandfertig" aus, um ihn zu bearbeiten.',
  DASH_GKS_NOT_ALLOWED:
    "Dieser Vorgang wurde über die zentrale Schnittstelle an das KBA versandt, somit ist keine Statusabfrage nötig.",
  VORGANG_ID_NOT_FOUND: "Der von Ihnen ausgewählte Antrag liegt nicht vor.",
  VORGANG_MAX_SELECTION_LIMIT_ERROR:
    "Sie haben die maximale Antragsauswahl von 200 überschritten. Bitte überprüfen Sie Ihre Auswahl.",
  MAX_SELECTION_LIMIT_ERROR:
    "Sie haben die maximale Antragsauswahl von 20 überschritten. Bitte überprüfen Sie Ihre Auswahl.",
  FILETYPE_VALIDATIONS:
    "Bitte wählen Sie eine CSV-Datei aus, um diese hochzuladen.",

  // Dashboard Vorgange List
  VORGANG_NO_DATA_FOUND_ACTIVE_TAB:
    "Leider können zu Ihrer Suchanfrage keine Ergebnisse gefunden werden. Möglicherweise befinden sich Vorgänge zu Ihrer Suche im Archiv.",

  VORGANG_NO_DATA_FOUND_ARCHIV_TAB: "Keine Datensätze zum Anzeigen",

  // Reducers Starts

  GET_TILEDETAILS_SUCCESS: "GET_TILEDETAILS_SUCCESS",
  GET_FEE_TILEDETAILS_SUCCESS: "GET_FEE_TILEDETAILS_SUCCESS",
  // Archive
  GET_ARCHIV_STATISTICS_SUCCESS: "GET_ARCHIV_STATISTICS_SUCCESS",
  GET_TENANT_TABLE_DATA_SUCCESS: "GET_TENANT_TABLE_DATA_SUCCESS",
  GET_TECHNICAL_ALIAS_SUCCESS: "GET_TECHNICAL_ALIAS_SUCCESS",
  GET_TENTANT_GROUPS_SUCCESS: "GET_TENTANT_GROUPS_SUCCESS",
  GET_FEE_REPORTING_TABLE_DATA_SUCCESS: "GET_FEE_REPORTING_TABLE_DATA_SUCCESS",

  // Get Tenants Request Types For Manual Request
  GET_TENANTS_REQUEST_TYPES_SUCCESS: "GET_TENANTS_REQUEST_TYPES_SUCCESS",
  CREATE_TENANTS_STATUS: "CREATE_TENANTS_STATUS",

  // Reducers Ends

  //Table colors--------------------------
  tableColors: {
    rowSelected: "#00C4DE",
    rowBackground: "#fff",
    leftBorderColor: "#FFF",
    rowDisabled: "#E6E6E6",
  },

  abrechnungstypFilterOption: {
    Großkunde: "Großkunde",
    Zulassungsbehörde: "Zulassungsbehörde",
  },
  monthOptions: [
    { label: "Januar", value: 1 },
    { label: "Februar", value: 2 },
    { label: "März", value: 3 },
    { label: "April", value: 4 },
    { label: "Mai", value: 5 },
    { label: "Juni", value: 6 },
    { label: "Juli", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "Oktober", value: 10 },
    { label: "November", value: 11 },
    { label: "Dezember", value: 12 },
  ],
  ZENDESK_LINK:
    "https://mydigitalcar.zendesk.com/auth/v2/login/signin?auth_origin=24676280493457%2Cfalse%2Ctrue&brand_id=24676280493457&locale=8&return_to=https%3A%2F%2Fmydigitalcar.zendesk.com%2Fagent&role=consumer&theme=hc",

  // App Dashboard In Complete Tenant Profile
  APP_DASHBOARD_IN_COMPLETE_TENANT_PROFILE:
    "Bitte wählen Sie ausschließlich vollständige Profile für den Export aus.",
};
