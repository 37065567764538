import axios from "axios";
import { config } from "../config";

/**
 * Get tenant table Details
 *
 * @param {*}
 * @returns
 */
const getTenantTableData = (query, groupId) => {
  let url;
  if (query) {
    url = `${config.apiUrl}/tcs/groups/${groupId}/tenants?size=${query.pageSize}&page=${query.page}`;

    if (query.orderBy && query.orderDirection !== "") {
      url += `&sort=${query.orderBy.field}&order=${query.orderDirection}`;
    }

    let errorFilter = "";
    if (query?.error) {
      errorFilter = `&error=${query.error}`;
    } else if (query?.success) {
      errorFilter = `&success=${query.success}`;
    }

    if (errorFilter) {
      url += errorFilter;
    }

    //handle filters and adding in query params with url---------
    if (query?.filters && query?.filters.length > 0) {
      let filters = "";
      query?.filters.map((filter) => {
        if (filters !== "") {
          filters += "&";
        }
        filters += `${filter.column.field}${filter.operator}${filter.value}`;
        return filters;
      });
      url += `&${filters}`;
    }
  } else {
    url = `${config.apiUrl}/tcs/groups/${groupId}/tenants`;
  }

  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

const getTenantgroups = () => {
  const url = `${config.apiUrl}/tcs/groups`;

  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Get Technical Alias and its Details
 *
 * @param {*}
 * @returns
 */
const getTechnicalAliasDetails = (tenantName) => {
  let url = `${config.apiUrl}/tcs/by-name/${tenantName}/details`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Accept Technical Alias
 *
 * @param {*}
 * @returns
 */

const acceptTechnicalAlias = (tenantData) => {
  const { tenantName, tenantAlias, groupId } = tenantData;
  let url = `${config.apiUrl}/tcs/approval/${tenantName}`;
  return axios
    .post(url, { tenantAlias, groupId })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Reject Technical Alias
 *
 * @param {*}
 * @returns
 */

const rejectTechnicalAlias = (tenantName) => {
  let url = `${config.apiUrl}/tcs/rejection/${tenantName}`;
  return axios
    .post(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Exporting Tenant Data in CSV
 *
 * @param {*}
 * @returns
 */
const exportTenantData = (tenantDetails) => {
  const { selectedTenants, selectedRequestsType, startDate, endDate } =
    tenantDetails;
  const params = new URLSearchParams({
    tenants: selectedTenants,
    vorgangsart: selectedRequestsType,
    from: startDate,
    to: endDate,
    group: tenantDetails?.group,
  })?.toString();

  let url = `${config.apiUrl}/tcs/reports/registered-requests?${params}`;
  return axios
    .get(url)
    .then((resp) => {
      if (resp?.status === 200) {
        window.open(url, "_self");
      }
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Get fee reporting table Details
 *
 * @param {*}
 * @returns
 */
const getfeeReportingTableData = (query, requestHeaders) => {
  let url;
  url = `${config.apiUrl}/vorgangs/reports/gebuhrenreport?size=${query?.pageSize}&page=${query?.page}`;

  if (query?.orderBy && query?.orderDirection !== "") {
    url += `&sort=${query.orderBy.field}&order=${query.orderDirection}`;
  }

  //handle filters and adding in query params with url---------
  if (query?.filters && query?.filters.length > 0) {
    let filters = "";
    query?.filters.map((filter) => {
      if (filters !== "") {
        filters += "&";
      }
      filters += `${filter.column.field}${filter.operator}${filter.value}`;
      return filters;
    });
    url += `&${filters}`;
  }

  return axios
    .get(url, { headers: requestHeaders })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

const getTenantsDeadletterQueue = async (tenantDetails) => {
  const { groupId, tenantsAlias } = tenantDetails;
  let url = `${config.apiUrl}/dlq/0709/count?group=${groupId}&tenants=${tenantsAlias}`;
  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
};

const getTenantsUnmatchedDeadletterQueue = async (tenantDetails) => {
  const { groupId, tenantsAlias } = tenantDetails;
  let url = `${config.apiUrl}/sabrina/orphan-0709/counts?group=${groupId}&tenants=${tenantsAlias}`;
  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
};

// Get Tenants Request Types For Manual Request
const getTenantsRequestTypes = async (tenantsAlias) => {
  let url = `${config.apiUrl}/tcs/${tenantsAlias}/registration-types`;

  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
};

// create Tenants via CSV upload
const createTenants = async (groupId, uploadedFileData) => {
  const url = `${config.apiUrl}/tenant-onboard/${groupId}`;

  const requestOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(url, uploadedFileData, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
};

async function getCreateTenantStatus(groupId) {
  const url = `${config.apiUrl}/tenant-onboard/${groupId}/status`;
  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
}

export const appLoginDashboardService = {
  getTenantTableData,
  getTechnicalAliasDetails,
  acceptTechnicalAlias,
  rejectTechnicalAlias,
  exportTenantData,
  getTenantgroups,
  getfeeReportingTableData,
  getTenantsDeadletterQueue,
  getTenantsRequestTypes,
  getTenantsUnmatchedDeadletterQueue,
  createTenants,
  getCreateTenantStatus,
};
