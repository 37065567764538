import axios from "axios";
import { config } from "../config";

// Upload CSV File
const uploadCSVFileForFinWhiteList = (groupId, fileData) => {
  const url = `${config.apiUrl}/fin-whitelist/${groupId}`;

  const requestOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axios
    .post(url, fileData, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
};

function getFinWhiteListStatusDetails(groupId) {
  const url = `${config.apiUrl}/fin-whitelist/${groupId}/status`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

const deleteCSVFinWhiteList = async (groupId) => {
  let url = `${config.apiUrl}/fin-whitelist/${groupId}`;
  try {
    const resp = await axios.delete(url);
    return resp?.status === 204;
  } catch (error) {
    return error;
  }
};

async function getFinWhiteListCSV(groupId) {
  const url = `${config.apiUrl}/fin-whitelist/${groupId}/csv`;

  try {
    const resp = await axios.get(url, { responseType: "blob" });
    return resp;
  } catch (error) {
    return error;
  }
}

function getFinWhiteTenantsList(groupId) {
  const url = `${config.apiUrl}/tcs/${groupId}/fin-whitelist/tenants`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

const updateFinWhiteTenantsList = (groupId, tenants) => {
  const url = `${config.apiUrl}/tcs/${groupId}/fin-whitelist/tenants`;

  return axios
    .patch(url, tenants)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
};

export const finWhiteListService = {
  uploadCSVFileForFinWhiteList,
  getFinWhiteListStatusDetails,
  deleteCSVFinWhiteList,
  getFinWhiteListCSV,

  // Get Fin white List
  getFinWhiteTenantsList,
  updateFinWhiteTenantsList,
};
