import React from "react";

// Components
import ErrorText from "../../shared/ErrorText";

// Constants
import {
  ZBTStaticOptions,
  IndividualAddressFieldsZB1,
  IndividualAddressFieldsZB2,
  zbtNameTypeOptions,
  zbAddressZustellungOptions,
  ZBGENERICERROR,
  zb1zb2InfoMessage,
} from "../../../constants/ManualRequestFormConstants";

// Core Components
import VollmachtsRelevantIcon from "../../core/VollmachtsRelevantIcon";
import MDCText from "../../../components/core/MDCText";
import MDCRadioButton from "../../core/MDCRadioButton";
import MDCSelect from "../../core/MDCSelect";
import MDCModal from "../../../components/core/MDCModal";
import MDCLabel from "../../core/MDCLabel";
import MDCButton from "../../core/MDCButton";
import MDCCheckbox from "../../core/MDCCheckbox";
import MDCTextArea from "../../core/MDCTextArea";

// Material UI
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../../themes/mdc-theme";

// Assets
import carLogo from "../../../assets/images/my_digital_car.svg";

const styles = () => ({
  bankInfoLabel: {
    color: theme.palette.MDCColors.textColor,
    fontFamily: theme.palette.typography.fontFamily,
    fontWeight: 600,
  },
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
});
class ZB1ZB2Addresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAddressModal: false,
      copyZB1Address: false,
    };
  }
  componentDidMount() {
    //For keeping copyZB1Address checkbox checked 1st time if we have ABHOLUNG as zb1zustellung type
    if (this.props.formValues?.zbt1ZustellungType?.value === "ABHOLUNG") {
      this.setState({ copyZB1Address: true });
    }
  }
  handleCheckbox = (e) => {
    this.setState({ copyZB1Address: e.target?.checked });
    if (e.target.checked) {
      this.props.setZB1AddressInZB2();
    }
  };

  getContentTextZBT = (zbAddressType) => {
    const {
      zbt1Unternehmen,
      zbt1PersonVorname,
      zbt1PersonNachname,
      zbt1Strasse,
      zbt1Stadt,
      zbt1Hausnummer,
      zbt1Postleitzahl,
      zbt1AddressType,
      zbt1IndividualAddresseType,
      zbt2Unternehmen,
      zbt2PersonVorname,
      zbt2PersonNachname,
      zbt2Strasse,
      zbt2Stadt,
      zbt2Hausnummer,
      zbt2Postleitzahl,
      zbt2AddressType,
      zbt2IndividualAddresseType,
    } = this.props?.formValues;

    // Creating Address Text for Successful ZB1 Address
    if (zbAddressType === "zbt1") {
      if (zbt1AddressType?.value === "adresseDesHalters") {
        return [ZBTStaticOptions[0].label];
      } else {
        return [
          `${
            zbt1AddressType?.value === "individuelleAddresse" &&
            zbt1IndividualAddresseType?.value === "naturliche"
              ? `${zbt1PersonVorname?.value} ${zbt1PersonNachname?.value}`
              : zbt1Unternehmen?.value
          }`,
          `${zbt1Strasse.value} ${zbt1Hausnummer?.value}`,
          `${zbt1Postleitzahl?.value} ${zbt1Stadt?.value}`,
        ];
      }
    }
    // Creating Address Text for Successful ZB2 Address
    if (zbAddressType === "zbt2") {
      if (zbt2AddressType?.value === "adresseDesHalters") {
        return [ZBTStaticOptions[0].label];
      } else {
        return [
          `${
            zbt2AddressType?.value === "individuelleAddresse" &&
            zbt2IndividualAddresseType?.value === "naturliche"
              ? `${zbt2PersonVorname?.value} ${zbt2PersonNachname.value}`
              : zbt2Unternehmen?.value
          }`,
          `${zbt2Strasse.value} ${zbt2Hausnummer?.value}`,
          `${zbt2Postleitzahl?.value} ${zbt2Stadt?.value}`,
        ];
      }
    }
  };

  handlezbt1ZustellungTypeChange = (e) => {
    this.props?.handleChange(e, 3);
    if (e.target.value === "ABHOLUNG") {
      this.setState({ copyZB1Address: true });
      this.props.setZB1AddressInZB2();
    }
  };

  handlezbt1AddressTypeChange = (e) => {
    this.props?.handleChange(e, 3);
    if (this.props.formValues?.zbt1ZustellungType?.value === "ABHOLUNG") {
      this.props.setZB1AddressInZB2();
    } else {
      this.setState({ copyZB1Address: false });
    }
  };
  showZustellungTypeInAddress = () => {
    if (
      this.props.formValues?.zbt1ZustellungType?.value ===
      zbAddressZustellungOptions[0].value
    ) {
      return zbAddressZustellungOptions[0].label;
    } else return zbAddressZustellungOptions[1].label;
  };

  closeZB1ZB2AddressModal = () => {
    if (
      this.props.formValues.zbt1ZustellungType.value === "ABHOLUNG" &&
      this.props.formValues.zbt1AddressType?.value === "individuelleAddresse"
    ) {
      //At the time of closing zb1zb2 address popup we are setting Abholung durch Individual address of zb1 in zb2
      this.props.setZB1AddressInZB2();
    }
    this.setState({ displayAddressModal: false });
  };

  render() {
    const ZBAddressTypeOptions = [
      ...ZBTStaticOptions,
      ...this.props?.operationIdsOptions,
    ]; // Merging operationIds list with static ZBT2 type options
    const showCompleteAddress = !this.props.isZustellungZBError;

    const getModalHeader = () => {
      return (
        <Grid container className={this.props.classes.headerCss}>
          <Grid item xs={8}>
            <MDCLabel variant="h4" label={"Zustellung ZB1 & ZB2"} />
          </Grid>
          <Grid item xs={4}>
            <img alt="My Digital Car" src={carLogo} />
          </Grid>
        </Grid>
      );
    };
    const getModalBody = () => {
      return (
        <Grid
          container
          className={this.props.classes.headerCss}
          paddingBottom="1rem"
        >
          <Grid container justifyContent={"space-evenly"}>
            {/**  ZBT1 Address Fields Starts......... */}

            <Grid item xs={5.5}>
              <Grid marginBottom={"1.75rem"} marginTop=".75rem">
                <MDCLabel label="Versand/Abholung ZB1 inkl. Plaketten" />
              </Grid>
              <Grid marginTop={".5rem"}>
                <MDCRadioButton
                  name="zbt1ZustellungType"
                  selected={this.props?.formValues?.zbt1ZustellungType?.value}
                  options={zbAddressZustellungOptions}
                  onChange={(e) => this.handlezbt1ZustellungTypeChange(e)}
                />
              </Grid>
              <MDCSelect
                value={
                  this.props?.formValues?.zbt1AddressType?.value
                    ? this.props?.formValues.zbt1AddressType?.value
                    : "" // Giving special empty value condition because with csv upload this value gets undefined and throws error
                }
                name="zbt1AddressType"
                handleChange={(e) => {
                  this.handlezbt1AddressTypeChange(e);
                }}
                disabled={
                  this.props?.isVollmachtCompleted || this.props?.readOnly
                }
                disableEmpty
                error={
                  this.props?.formValues?.zbt1AddressType?.error.length > 0 &&
                  !this.props?.readOnly
                }
                placeholder={"Bitte auswählen"}
                options={ZBAddressTypeOptions}
              />
              <ErrorText
                id={"zbt1AddressType-error"}
                error={this.props?.formValues?.zbt1AddressType?.error}
              />

              {!["adresseDesHalters", ""].includes(
                this.props?.formValues?.zbt1AddressType?.value
              ) && (
                <Grid container justifyContent={"space-between"}>
                  <Grid
                    item
                    xs={12}
                    className={this.props.classes.fieldGroup}
                    marginY={
                      this.props?.formValues?.zbt1AddressType.value ===
                      "individuelleAddresse"
                        ? "1rem"
                        : "3.4rem"
                    }
                  >
                    {this.props?.formValues?.zbt1AddressType.value ===
                      "individuelleAddresse" && (
                      <>
                        <MDCLabel label="Möchten Sie den Unternehmens- oder Personennamen angeben?" />
                        <Grid container>
                          <MDCRadioButton
                            name="zbt1IndividualAddresseType"
                            selected={
                              this.props?.formValues?.zbt1IndividualAddresseType
                                ?.value
                            }
                            options={zbtNameTypeOptions}
                            disabled={
                              this.props?.isVollmachtCompleted ||
                              this.props?.readOnly
                            }
                            onChange={(e) => this.props?.handleChange(e, 3)}
                            row
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {this.props?.formValues?.zbt1IndividualAddresseType.value ===
                    "naturliche" &&
                  this.props?.formValues?.zbt1AddressType.value ===
                    "individuelleAddresse" ? (
                    <>
                      <Grid item xs={5.5} marginBottom=".75rem">
                        <MDCLabel label={"Vorname"} />
                        <MDCText
                          name={"zbt1PersonVorname"}
                          value={
                            this.props?.formValues?.zbt1PersonVorname?.value
                          }
                          readOnly={
                            this.props?.isVollmachtCompleted ||
                            this.props?.readOnly
                          }
                          onChange={(e) => this.props?.handleChange(e, 3)}
                          error={
                            this.props.formValues.zbt1PersonVorname.error
                              .length > 0
                          }
                          maxLength={60}
                        />
                        <ErrorText
                          id={"zbt1PersonVorname-error"}
                          error={
                            this.props?.formValues?.zbt1PersonVorname?.error
                          }
                        />
                      </Grid>
                      <Grid item xs={5.5} marginBottom=".75rem">
                        <MDCLabel label={"Nachname"} />
                        <MDCText
                          name={"zbt1PersonNachname"}
                          value={
                            this.props?.formValues?.zbt1PersonNachname?.value
                          }
                          readOnly={
                            this.props?.isVollmachtCompleted ||
                            this.props?.readOnly
                          }
                          error={
                            this.props.formValues.zbt1PersonNachname?.error
                              .length > 0
                          }
                          onChange={(e) => this.props?.handleChange(e, 3)}
                          maxLength={45}
                        />
                        <ErrorText
                          id={"zbt1PersonNachname-error"}
                          error={
                            this.props?.formValues?.zbt1PersonNachname?.error
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} marginBottom=".75rem">
                      <MDCLabel label={"Unternehmen"} />
                      <MDCText
                        name={"zbt1Unternehmen"}
                        value={this.props?.formValues?.zbt1Unternehmen?.value}
                        readOnly={
                          this.props?.isVollmachtCompleted ||
                          this.props?.readOnly
                        }
                        error={
                          this.props.formValues.zbt1Unternehmen?.error.length >
                          0
                        }
                        onChange={(e) => this.props?.handleChange(e, 3)}
                        maxLength={150}
                      />
                      <ErrorText
                        id={"zbt1Unternehmen-error"}
                        error={this.props?.formValues?.zbt1Unternehmen?.error}
                      />
                    </Grid>
                  )}
                  {IndividualAddressFieldsZB1.map(
                    ({ fieldId, fieldLabel, maxLength }, index) => {
                      return (
                        <Grid
                          item
                          xs={
                            fieldId === "zbt1Strasse"
                              ? 7.5
                              : fieldId === "zbt1Hausnummer"
                              ? 4
                              : 5.5
                          }
                          key={index}
                          marginBottom=".75rem"
                        >
                          <MDCLabel label={fieldLabel} />
                          <MDCText
                            name={fieldId}
                            value={this.props?.formValues[fieldId].value}
                            readOnly={
                              this.props?.isVollmachtCompleted ||
                              this.props?.readOnly
                            }
                            maxLength={maxLength}
                            error={
                              this.props.formValues[fieldId]?.error.length > 0
                            }
                            isToolTipText={
                              fieldLabel === "Nummer" &&
                              (this.props?.isVollmachtCompleted ||
                                this.props?.readOnly)
                                ? this.props?.formValues[fieldId]?.value
                                : ""
                            }
                            onChange={(e) => this.props?.handleChange(e, 3)}
                          />
                          <ErrorText
                            id={`${fieldId}-error`}
                            error={this.props?.formValues[fieldId]?.error}
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )}
            </Grid>

            {/**  ZBT1 Address Fields Ends......... */}

            {/**  ZBT2 Address Fields Starts......... */}

            <Grid item xs={5.5}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <MDCLabel label="Versand/Abholung ZB2" />
                <MDCCheckbox
                  checked={this.state.copyZB1Address}
                  label={"Entsprechend ZB1"}
                  onChange={this.handleCheckbox}
                  disabled={
                    this.props?.isVollmachtCompleted ||
                    this.props?.readOnly ||
                    this.props?.formValues?.zbt1ZustellungType?.value ===
                      "ABHOLUNG"
                  }
                />
              </Grid>
              <Grid marginTop={".5rem"}>
                <MDCRadioButton
                  name="zbt2ZustellungType"
                  selected={this.props?.formValues?.zbt1ZustellungType?.value}
                  options={zbAddressZustellungOptions}
                  onChange={(e) => this.props.handleChange(e, 3)}
                  disabled={this.state.copyZB1Address}
                />
              </Grid>
              <Grid marginTop={this.state.copyZB1Address ? ".55rem" : ".15rem"}>
                <MDCSelect
                  value={
                    this.props?.formValues?.zbt2AddressType?.value
                      ? this.props?.formValues.zbt2AddressType?.value
                      : "" // Giving special empty value condition because with csv upload this value gets undefined and throws error
                  }
                  name="zbt2AddressType"
                  handleChange={(e) => {
                    this.setState({ copyZB1Address: false });
                    this.props?.handleChange(e, 3);
                  }}
                  disabled={
                    this.props?.isVollmachtCompleted ||
                    this.props?.readOnly ||
                    this.state.copyZB1Address
                  }
                  disableEmpty
                  error={
                    this.props?.formValues.zbt2AddressType.error.length > 0 &&
                    !this.props?.readOnly
                  }
                  placeholder={"Bitte auswählen"}
                  options={ZBAddressTypeOptions}
                />
                <ErrorText
                  id={"zbt2AddressType-error"}
                  error={this.props?.formValues?.zbt2AddressType?.error}
                />
              </Grid>
              {!(
                this.state.copyZB1Address ||
                ["adresseDesHalters", ""].includes(
                  this.props?.formValues?.zbt2AddressType.value
                )
              ) && (
                <Grid container justifyContent={"space-between"}>
                  <Grid
                    item
                    xs={12}
                    className={this.props.classes.fieldGroup}
                    marginY={
                      this.props?.formValues?.zbt2AddressType.value ===
                      "individuelleAddresse"
                        ? "1rem"
                        : "3.4rem"
                    }
                  >
                    {this.props?.formValues?.zbt2AddressType.value ===
                      "individuelleAddresse" && (
                      <>
                        <MDCLabel label="Möchten Sie den Unternehmens- oder Personennamen angeben?" />
                        <Grid container>
                          <MDCRadioButton
                            name="zbt2IndividualAddresseType"
                            selected={
                              this.props?.formValues?.zbt2IndividualAddresseType
                                ?.value
                            }
                            options={zbtNameTypeOptions}
                            disabled={
                              this.props?.isVollmachtCompleted ||
                              this.props?.readOnly ||
                              this.state.copyZB1Address
                            }
                            onChange={(e) => this.props?.handleChange(e, 3)}
                            row
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {this.props?.formValues?.zbt2IndividualAddresseType.value ===
                    "naturliche" &&
                  this.props?.formValues?.zbt2AddressType.value ===
                    "individuelleAddresse" ? (
                    <>
                      <Grid item xs={5.5} marginBottom=".75rem">
                        <MDCLabel label={"Vorname"} />
                        <MDCText
                          name={"zbt2PersonVorname"}
                          value={
                            this.props?.formValues?.zbt2PersonVorname?.value
                          }
                          readOnly={
                            this.props?.isVollmachtCompleted ||
                            this.props?.readOnly ||
                            this.state.copyZB1Address
                          }
                          error={
                            this.props.formValues.zbt2PersonVorname?.error
                              .length > 0
                          }
                          onChange={(e) => this.props?.handleChange(e, 3)}
                          maxLength={60}
                        />
                        <ErrorText
                          id={"zbt2PersonVorname-error"}
                          error={
                            this.props?.formValues?.zbt2PersonVorname?.error
                          }
                        />
                      </Grid>
                      <Grid item xs={5.5} marginBottom=".75rem">
                        <MDCLabel label={"Nachname"} />
                        <MDCText
                          name={"zbt2PersonNachname"}
                          value={
                            this.props?.formValues?.zbt2PersonNachname?.value
                          }
                          readOnly={
                            this.props?.isVollmachtCompleted ||
                            this.props?.readOnly ||
                            this.state.copyZB1Address
                          }
                          error={
                            this.props.formValues.zbt2PersonNachname?.error
                              .length > 0
                          }
                          onChange={(e) => this.props?.handleChange(e, 3)}
                          maxLength={45}
                        />
                        <ErrorText
                          id={"zbt2PersonNachname-error"}
                          error={
                            this.props?.formValues?.zbt2PersonNachname?.error
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} marginBottom=".75rem">
                      <MDCLabel label={"Unternehmen"} />
                      <MDCText
                        name={"zbt2Unternehmen"}
                        value={this.props?.formValues?.zbt2Unternehmen?.value}
                        readOnly={
                          this.props?.isVollmachtCompleted ||
                          this.props?.readOnly ||
                          this.state.copyZB1Address
                        }
                        error={
                          this.props.formValues.zbt2Unternehmen?.error.length >
                          0
                        }
                        onChange={(e) => this.props?.handleChange(e, 3)}
                        maxLength={150}
                      />
                      <ErrorText
                        id={"zbt2Unternehmen-error"}
                        error={this.props?.formValues?.zbt2Unternehmen?.error}
                      />
                    </Grid>
                  )}
                  {IndividualAddressFieldsZB2.map(
                    ({ fieldId, fieldLabel, maxLength }, index) => {
                      return (
                        <Grid
                          item
                          xs={
                            fieldId === "zbt2Strasse"
                              ? 7.5
                              : fieldId === "zbt2Hausnummer"
                              ? 4
                              : 5.5
                          }
                          key={index}
                          marginBottom=".75rem"
                        >
                          <MDCLabel label={fieldLabel} />
                          <MDCText
                            name={fieldId}
                            value={this.props?.formValues[fieldId].value}
                            readOnly={
                              this.props?.isVollmachtCompleted ||
                              this.props?.readOnly ||
                              this.state.copyZB1Address
                            }
                            maxLength={maxLength}
                            error={
                              this.props.formValues[fieldId]?.error.length > 0
                            }
                            isToolTipText={
                              fieldLabel === "Nummer" &&
                              (this.props?.isVollmachtCompleted ||
                                this.props?.readOnly)
                                ? this.props?.formValues[fieldId]?.value
                                : ""
                            }
                            onChange={(e) => this.props?.handleChange(e, 3)}
                          />
                          <ErrorText
                            id={`${fieldId}-error`}
                            error={this.props?.formValues[fieldId]?.error}
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              )}
            </Grid>

            {/**  ZBT2 Address Fields Ends......... */}
          </Grid>
        </Grid>
      );
    };
    const getModalFooter = () => {
      return (
        <Grid
          container
          justifyContent={"end"}
          id="footer-button"
          paddingX="2rem"
          paddingBottom={"1.5rem"}
        >
          <MDCButton
            variant="contained"
            label={"Bestätigen"}
            onClick={this.closeZB1ZB2AddressModal}
          />
        </Grid>
      );
    };

    return (
      <Grid container>
        <>
          <Grid>
            <MDCLabel label={"Zustellung ZB1 & ZB2"} isReadOnly />
            {this.props.isQESIconDisplay && (
              <VollmachtsRelevantIcon
                isQESCompleted={this.props.isVollmachtCompleted}
              />
            )}
          </Grid>
          {showCompleteAddress && (
            <Grid container>
              {this.props?.formValues?.zbt1AddressType.value !== "" && (
                <MDCTextArea
                  id="zbt1AddressDisplay"
                  isCommaText={
                    !(
                      this.props?.formValues.zbt1AddressType?.value ===
                      "adresseDesHalters"
                    )
                  }
                  contentText={this.getContentTextZBT("zbt1")}
                  headerText={`ZB1: ${this.showZustellungTypeInAddress()}`}
                />
              )}
              {this.props?.formValues?.zbt2AddressType.value !== "" && (
                <MDCTextArea
                  id="zbt2AddressDisplay"
                  isCommaText={
                    !(
                      this.props?.formValues.zbt2AddressType?.value ===
                      "adresseDesHalters"
                    )
                  }
                  contentText={this.getContentTextZBT("zbt2")}
                  headerText={`ZB2: ${this.showZustellungTypeInAddress()}`}
                />
              )}
            </Grid>
          )}

          <Grid container flexDirection={"column"}>
            <MDCButton
              variant="outlined"
              label={
                showCompleteAddress &&
                ![
                  this.props?.formValues?.zbt1AddressType.value,
                  this.props?.formValues?.zbt1AddressType.value,
                ].includes("")
                  ? "Adresse/n bearbeiten"
                  : "Adressen hinzufügen"
              }
              onClick={() => this.setState({ displayAddressModal: true })}
              disabled={
                this.props?.isDetailDialog ||
                this.props?.isVollmachtCompleted ||
                this.props?.readOnly
              }
            />
          </Grid>
          <ErrorText
            id="error"
            error={this.props?.isZustellungZBError ? ZBGENERICERROR : ""}
          />
          {!this.props?.isDetailDialog && (
            <Grid marginTop={".5rem"}>
              <MDCLabel label={zb1zb2InfoMessage} isReadOnly />
            </Grid>
          )}
        </>

        <MDCModal
          open={this.state.displayAddressModal}
          title={getModalHeader()}
          body={getModalBody()}
          footer={getModalFooter()}
          isScroll
          size="xl"
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(ZB1ZB2Addresses);
