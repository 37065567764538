import React, { Component } from "react";

// Components
import ErrorText from "../shared/ErrorText";

// Core Components
import MDCCheckbox from "../core/MDCCheckbox";
import MDCLabel from "../core/MDCLabel";
import MDCRadioButton from "../core/MDCRadioButton";
import InputAdornment from "@mui/material/InputAdornment";

import {
  DuplicateRadioValue,
  DuplicateCheckboxValue,
  DuplicateTextField,
} from "./DuplicateControls";

// Helper
import { kennzeichenReservierungRadioLabel } from "../../helpers/vorgange";
import MDCText from "../core/MDCText";
//Constants
import {
  kennzeichenZuweisenRadioOptions,
  yesORnoOptions,
} from "../../constants/ManualRequestFormConstants";
// Material UI
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";

// Components
import OrderLicensePlateSection from "./inner_components/OrderLicensePlateSection";

const styles = () => ({
  formBackGround: {
    padding: "0 3%",
    paddingTop: "4%",
    marginTop: "2% !important",
    background: `${theme.palette.MDCColors.white} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.MDCColors.color18}`,
    borderRadius: "5px",
    opacity: 1,
    color: theme.palette.MDCColors.textColor,
    fontSize: "1rem",
    fontFamily: theme.palette.typography.fontFamily,
    lineHeight: 1,
  },
  detailViewBackground: {
    boxShadow: "none",
    paddingTop: "initial",
  },
  vollmachtCompleted: {
    paddingTop: "1%",
  },
  columnTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: theme.palette.typography.fontFamily,
    marginBottom: "1.25rem",
  },
  fieldGroup: {
    marginBottom: "1.25rem !important",
  },
  linkLabel: {
    color: theme.palette.MDCColors.color23,
    cursor: "pointer",
  },
  inputAdornmentStyles: {
    marginLeft: "-1rem",
    color: `${theme.palette.MDCColors.color23} !important`,
    marginTop: "0.5rem",
  },
});

class KennzeichenRequestForm extends Component {
  formatDisplayDate = (date) => {
    let dateParts = date?.split("-");
    return dateParts.length > 0
      ? `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
      : "";
  };

  render() {
    const kennzeichenZuweisenOptionValue = parseInt(
      this.props?.formValues?.kennzeichenZuweisenOption?.value
    );
    const { classes } = this.props;
    const vorgangsart = this.props?.vorgangsart?.value;
    const isNZRequest = vorgangsart === "NZ"; // New Registration
    const isTZRequest = vorgangsart === "TZ";
    const isSORequest = vorgangsart === "SO";
    const isReRegistrationRequest = ["UM", "WZ", "HA"].includes(vorgangsart); // For ReRegistration either complex(UM) or simple(WZ)
    const isABRequest = vorgangsart === "AB"; // Deregistration
    const isUMRequest = vorgangsart === "UM"; // Complex Reregistration
    const isHARequest = vorgangsart === "HA"; // Reregistration: change for car owner name

    const numberPlateF = isReRegistrationRequest
      ? "OldKennzeichenF"
      : "kennzeichenF";
    const numberPlateM = isReRegistrationRequest
      ? "OldKennzeichenM"
      : "kennzeichenM";
    const numberPlateL = isReRegistrationRequest
      ? "OldKennzeichenL"
      : "kennzeichenL";
    const numberPlateType = isReRegistrationRequest
      ? "OldKennzeichen"
      : "Kennzeichen";
    const deAndReregisterNumberPlate = (
      <Grid container>
        <Grid item xs={12} className={classes.fieldGroup}>
          <MDCLabel label="Kennzeichen" />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MDCText
                name={numberPlateF}
                value={this.props.formValues[numberPlateF]?.value?.trim()}
                onChange={(e) => this.props.handleChange(e, 2)}
                readOnly={this.props.readOnly}
                error={
                  this.props.formValues[numberPlateType].error.length > 0 &&
                  !this.props.readOnly
                }
                onKeyPress={(event) => {
                  if (!/[A-Za-zÄÖÜ]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} // only allowing alphabets
                maxLength={3}
              />
              {this.props.formValues[numberPlateF].duplicate && (
                <DuplicateTextField
                  name={numberPlateF}
                  value={this.props.formValues[numberPlateF]?.duplicate}
                  readOnly
                  step={this.props.step}
                  onAccept={() =>
                    this.props.resolveConflict(numberPlateF, true)
                  }
                  onReject={() =>
                    this.props.resolveConflict(numberPlateF, false)
                  }
                  maxLength={3}
                />
              )}
            </Grid>

            <Grid item xs={3}>
              <MDCText
                name={numberPlateM}
                value={this.props.formValues[numberPlateM]?.value?.trim()}
                onChange={(e) => this.props.handleChange(e, 2)}
                readOnly={this.props.readOnly}
                error={
                  this.props.formValues[numberPlateType].error.length > 0 &&
                  !this.props.readOnly
                }
                onKeyPress={(event) => {
                  if (!/[A-Za-z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} // only allowing alphabets
                maxLength={2}
              />

              {this.props.formValues[numberPlateM]?.duplicate && (
                <DuplicateTextField
                  name={numberPlateM}
                  value={this.props.formValues[numberPlateM].duplicate}
                  readOnly
                  step={this.props.step}
                  onAccept={() =>
                    this.props.resolveConflict(numberPlateM, true)
                  }
                  onReject={() =>
                    this.props.resolveConflict(numberPlateM, false)
                  }
                  maxLength={2}
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <MDCText
                name={numberPlateL}
                value={this.props.formValues[numberPlateL]?.value?.trim()}
                onChange={(e) => this.props.handleChange(e, 2)}
                readOnly={this.props.readOnly}
                error={
                  this.props.formValues[numberPlateType].error.length > 0 &&
                  !this.props.readOnly
                }
                endAdornment={
                  this.props?.formValues?.MerkmalElektrokennzeichen?.value ? (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornmentStyles}
                    >
                      <b>E</b>
                    </InputAdornment>
                  ) : (
                    ""
                  )
                }
                maxLength={4}
              />

              {this.props.formValues[numberPlateL].duplicate && (
                <DuplicateTextField
                  name={numberPlateL}
                  value={this.props.formValues[numberPlateL].duplicate}
                  readOnly
                  step={this.props.step}
                  onAccept={() =>
                    this.props.resolveConflict(numberPlateL, true)
                  }
                  onReject={() =>
                    this.props.resolveConflict(numberPlateL, false)
                  }
                  maxLength={4}
                />
              )}
            </Grid>
            {!this.props?.formValues?.kennzeichenZuweisenOption.hasOwnProperty(
              "duplicate"
            ) &&
              (this.props.formValues[numberPlateType]?.duplicate ||
                this.props.formValues?.pin?.duplicate) && (
                <Grid item xs={12}>
                  <DuplicateTextField
                    name={numberPlateType}
                    value={this.props.formValues[numberPlateType]?.duplicate}
                    readOnly
                    step={this.props.step}
                    onAccept={() =>
                      this.props.resolveConflict(numberPlateType, true)
                    }
                    onReject={() =>
                      this.props.resolveConflict(numberPlateType, false)
                    }
                  />
                </Grid>
              )}
          </Grid>

          {!this.props.readOnly && (
            <ErrorText
              id={`${numberPlateType}-error`}
              error={this.props.formValues[numberPlateType].error}
            />
          )}
        </Grid>
      </Grid>
    );

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          className={`${classes.formBackGround} ${
            this.props.isDetailDialog ? classes.detailViewBackground : ""
          } `}
        >
          <div>
            {!this.props.isDetailDialog && (
              <>{this.props.selectDuplicateValues()}</>
            )}

            <Grid container spacing={4}>
              {(isReRegistrationRequest ||
                this.props.vorgangsart.value === "AB") && (
                <Grid item xs={4}>
                  {isReRegistrationRequest ? (
                    <>
                      <Grid>
                        <label className={classes.columnTitle}>
                          Kennzeicheninformationen (bisheriges Kennzeichen)
                        </label>
                      </Grid>
                      <MDCLabel label="Soll das alte Kennzeichen behalten werden?" />
                      <Grid marginTop="1rem">
                        <MDCRadioButton
                          name="UmmeldungOldKennzeichenRetained"
                          selected={
                            this.props.formValues
                              ?.UmmeldungOldKennzeichenRetained?.value || "nein"
                          }
                          options={yesORnoOptions}
                          disabled={this.props.readOnly}
                          onChange={(e) => this.props.handleChange(e, 2)}
                          row
                        />
                      </Grid>
                    </>
                  ) : (
                    <div>
                      <label className={classes.columnTitle}>
                        Kennzeicheninformationen
                      </label>
                    </div>
                  )}
                  {(isReRegistrationRequest ||
                    this.props.vorgangsart.value === "AB") && (
                    <>
                      {deAndReregisterNumberPlate}
                      {!(
                        (isUMRequest || isHARequest) &&
                        this.props.formValues?.UmmeldungOldKennzeichenRetained
                          ?.value === "ja"
                      ) && (
                        // Hide Hinten and vorne for complex ReRegistration (UM request) and "UmmeldungOldKennzeichenRetained attribute YES case"
                        <>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <MDCLabel
                              label="Kennzeichensicherheitscode vorne"
                              isOptional={
                                isABRequest &&
                                this.props?.formValues?.ZweiraderKennzeichen
                                  ?.value
                                  ? true
                                  : false
                              }
                            />
                            <MDCText
                              name="sicherheitscode_vorn"
                              value={
                                this.props.formValues.sicherheitscode_vorn.value
                              }
                              onChange={(e) => this.props.handleChange(e, 2)}
                              readOnly={this.props.readOnly}
                              error={
                                this.props.formValues.sicherheitscode_vorn.error
                                  .length > 0
                              }
                              maxLength={3}
                            />

                            {this.props.formValues.sicherheitscode_vorn.hasOwnProperty(
                              "duplicate"
                            ) && (
                              <DuplicateTextField
                                name="sicherheitscode_vorn"
                                value={
                                  this.props.formValues.sicherheitscode_vorn
                                    .duplicate
                                }
                                readOnly
                                step={this.props.step}
                                onAccept={() =>
                                  this.props.resolveConflict(
                                    "sicherheitscode_vorn",
                                    true
                                  )
                                }
                                onReject={() =>
                                  this.props.resolveConflict(
                                    "sicherheitscode_vorn",
                                    false
                                  )
                                }
                                maxLength={3}
                              />
                            )}

                            <ErrorText
                              id="sicherheitscode-vorn-error"
                              error={
                                this.props.formValues.sicherheitscode_vorn.error
                              }
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <MDCLabel label="Kennzeichensicherheitscode hinten" />
                            <MDCText
                              name="sicherheitscode_hinten"
                              value={
                                this.props.formValues.sicherheitscode_hinten
                                  .value
                              }
                              onChange={(e) => this.props.handleChange(e, 2)}
                              readOnly={this.props.readOnly}
                              error={
                                this.props.formValues.sicherheitscode_hinten
                                  .error.length > 0
                              }
                              maxLength={3}
                            />

                            {this.props.formValues.sicherheitscode_hinten.hasOwnProperty(
                              "duplicate"
                            ) && (
                              <DuplicateTextField
                                name="sicherheitscode_hinten"
                                value={
                                  this.props.formValues.sicherheitscode_hinten
                                    .duplicate
                                }
                                readOnly
                                step={this.props.step}
                                onAccept={() =>
                                  this.props.resolveConflict(
                                    "sicherheitscode_hinten",
                                    true
                                  )
                                }
                                onReject={() =>
                                  this.props.resolveConflict(
                                    "sicherheitscode_hinten",
                                    false
                                  )
                                }
                                maxLength={3}
                              />
                            )}

                            <ErrorText
                              id="sicherheitscode-hinten-error"
                              error={
                                this.props.formValues.sicherheitscode_hinten
                                  .error
                              }
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              )}
              {(isNZRequest ||
                isTZRequest ||
                isSORequest ||
                (isReRegistrationRequest &&
                  this.props.formValues?.UmmeldungOldKennzeichenRetained
                    .value !== "ja")) && (
                <Grid item xs={4}>
                  <div>
                    <label className={classes.columnTitle}>
                      Kennzeichenreservierung
                    </label>
                    <Grid container>
                      <Grid item xs={12}>
                        <MDCRadioButton
                          name="kennzeichenZuweisenOption"
                          selected={kennzeichenZuweisenOptionValue}
                          options={kennzeichenZuweisenRadioOptions}
                          onChange={(e) => this.props.handleChange(e, 2)}
                          disabled={this.props.readOnly}
                        />
                      </Grid>

                      {(kennzeichenZuweisenOptionValue === 1) && (
                        <Grid item xs={12} marginBottom="1rem" marginTop="1rem">
                          <MDCLabel label="Kennzeichenkreis/Wunschkennzeichen" />
                          <Grid container>
                            <Grid item xs={3} marginRight=".4rem">
                              <MDCText
                                name="kennzeichenF"
                                value={this.props.formValues?.kennzeichenF?.value?.trim()}
                                onChange={(e) => this.props.handleChange(e, 2)}
                                readOnly={this.props.readOnly}
                                error={
                                  this.props.formValues.Kennzeichen.error
                                    .length > 0 && !this.props.readOnly
                                }
                                onKeyPress={(event) => {
                                  if (!/[A-Za-zÄÖÜ]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }} // only allowing alphabets
                                maxLength={3}
                              />

                              {this.props.formValues.kennzeichenF.duplicate &&
                                !this.props.formValues.separateLicenseBlock && (
                                  <>
                                    <DuplicateTextField
                                      name="kennzeichenF"
                                      value={
                                        this.props.formValues.kennzeichenF
                                          .duplicate
                                      }
                                      readOnly
                                      step={this.props.step}
                                      onAccept={() =>
                                        this.props.resolveConflict(
                                          "kennzeichenF",
                                          true
                                        )
                                      }
                                      onReject={() =>
                                        this.props.resolveConflict(
                                          "kennzeichenF",
                                          false
                                        )
                                      }
                                      maxLength={3}
                                    />
                                  </>
                                )}
                            </Grid>

                            <Grid item xs={3} marginRight=".4rem">
                              <MDCText
                                name="kennzeichenM"
                                value={this.props.formValues?.kennzeichenM?.value?.trim()}
                                onChange={(e) => this.props.handleChange(e, 2)}
                                readOnly={this.props.readOnly}
                                error={
                                  this.props.formValues.Kennzeichen.error
                                    .length > 0 && !this.props.readOnly
                                }
                                onKeyPress={(event) => {
                                  if (!/[A-Za-z]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }} // only allowing alphabets
                                maxLength={2}
                              />

                              {this.props.formValues.kennzeichenM.duplicate &&
                                !this.props.formValues.separateLicenseBlock && (
                                  <>
                                    <DuplicateTextField
                                      name="kennzeichenM"
                                      value={
                                        this.props.formValues.kennzeichenM
                                          .duplicate
                                      }
                                      readOnly
                                      step={this.props.step}
                                      onAccept={() =>
                                        this.props.resolveConflict(
                                          "kennzeichenM",
                                          true
                                        )
                                      }
                                      onReject={() =>
                                        this.props.resolveConflict(
                                          "kennzeichenM",
                                          false
                                        )
                                      }
                                      maxLength={2}
                                    />
                                  </>
                                )}
                            </Grid>
                            <Grid item xs={5}>
                              <MDCText
                                name="kennzeichenL"
                                value={this.props.formValues?.kennzeichenL?.value?.trim()}
                                onChange={(e) => this.props.handleChange(e, 2)}
                                readOnly={this.props.readOnly}
                                error={
                                  this.props.formValues.Kennzeichen.error
                                    .length > 0 && !this.props.readOnly
                                }
                                endAdornment={
                                  this.props?.formValues
                                    ?.MerkmalElektrokennzeichen?.value ? (
                                    <InputAdornment
                                      className={classes.inputAdornmentStyles}
                                      position="start"
                                    >
                                      <b>E</b>
                                    </InputAdornment>
                                  ) : (
                                    ""
                                  )
                                }
                                maxLength={4}
                              />

                              {this.props.formValues.kennzeichenL.duplicate &&
                                !this.props.formValues.separateLicenseBlock && (
                                  <>
                                    <DuplicateTextField
                                      name="kennzeichenL"
                                      value={
                                        this.props.formValues.kennzeichenL
                                          .duplicate
                                      }
                                      readOnly
                                      step={this.props.step}
                                      onAccept={() =>
                                        this.props.resolveConflict(
                                          "kennzeichenL",
                                          true
                                        )
                                      }
                                      onReject={() =>
                                        this.props.resolveConflict(
                                          "kennzeichenL",
                                          false
                                        )
                                      }
                                      maxLength={4}
                                    />
                                  </>
                                )}
                            </Grid>

                            {!this.props.readOnly && (
                              <Grid item xs={12}>
                                <ErrorText
                                  id="Kennzeichen-error"
                                  error={
                                    this.props.formValues.Kennzeichen.error
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {kennzeichenZuweisenOptionValue === 1 && (
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="PIN für reserviertes Kennzeichen" />
                          <MDCText
                            name="pin"
                            value={this.props.formValues.pin.value}
                            onChange={(e) => this.props.handleChange(e, 2)}
                            readOnly={this.props.readOnly}
                            error={
                              this.props.formValues.pin.error.length > 0 &&
                              !this.props.readOnly
                            }
                            maxLength={8}
                          />

                          {this.props.formValues.pin.duplicate &&
                            !this.props.formValues.separateLicenseBlock && (
                              <>
                                <DuplicateTextField
                                  name="pin"
                                  value={this.props.formValues.pin.duplicate}
                                  readOnly
                                  step={this.props.step}
                                  onAccept={() =>
                                    this.props.resolveConflict("pin", true)
                                  }
                                  onReject={() =>
                                    this.props.resolveConflict("pin", false)
                                  }
                                  maxLength={8}
                                />
                              </>
                            )}
                          {!this.props.readOnly && (
                            <ErrorText
                              id="pin-error"
                              error={this.props.formValues.pin.error}
                            />
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12} className={classes.fieldGroup}>
                        {!this.props?.formValues?.kennzeichenZuweisenOption.hasOwnProperty(
                          "duplicate"
                        ) &&
                          (this.props.formValues?.Kennzeichen?.duplicate ||
                            this.props.formValues?.pin?.duplicate) && (
                            <DuplicateTextField
                              name="Kennzeichen"
                              value={
                                this.props.formValues.Kennzeichen.duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict("Kennzeichen", true)
                              }
                              onReject={() =>
                                this.props.resolveConflict("Kennzeichen", false)
                              }
                            />
                          )}
                      </Grid>
                    </Grid>

                    {this.props.formValues.kennzeichenZuweisenOption.hasOwnProperty(
                      "duplicate"
                    ) &&
                      this.props.formValues.separateLicenseBlock && (
                        <DuplicateRadioValue
                          name="kennzeichenZuweisenOption"
                          value={
                            this.props.formValues.kennzeichenZuweisenOption
                              .duplicate
                          }
                          label={
                            kennzeichenReservierungRadioLabel[
                              this.props.formValues.kennzeichenZuweisenOption
                                .duplicate
                            ]
                          }
                          duplicateLicense={[
                            this.props.formValues.kennzeichenF?.duplicate,
                            this.props.formValues.kennzeichenM?.duplicate,
                            this.props.formValues.kennzeichenL?.duplicate,
                            this.props.formValues.pin?.duplicate,
                          ]}
                          step={this.props.step}
                          onAccept={() =>
                            this.props.resolveConflict(
                              "kennzeichenZuweisenOption",
                              true
                            )
                          }
                          onReject={() =>
                            this.props.resolveConflict(
                              "kennzeichenZuweisenOption",
                              false
                            )
                          }
                        />
                      )}
                  </div>
                </Grid>
              )}
              <Grid item xs={4}>
                <div>
                  <label className={classes.columnTitle}>Kennzeichenart</label>
                  <Grid container>
                    <Grid item xs={12} className={classes.fieldGroup}>
                      <MDCCheckbox
                        name="MerkmalElektrokennzeichen"
                        label="E - Kennzeichen"
                        checked={
                          this.props?.formValues?.MerkmalElektrokennzeichen
                            ?.value
                        }
                        onChange={(e) => this.props.handleChange(e, 2)}
                        disabled={this.props.readOnly}
                      />
                    </Grid>
                    {this.props.formValues.MerkmalElektrokennzeichen.hasOwnProperty(
                      "duplicate"
                    ) && (
                      <DuplicateCheckboxValue
                        name="MerkmalElektrokennzeichen"
                        label="E - Kennzeichen"
                        checked={
                          this.props.formValues.MerkmalElektrokennzeichen
                            .duplicate
                        }
                        disabled={this.props.readOnly}
                        step={this.props.step}
                        onAccept={() =>
                          this.props.resolveConflict(
                            "MerkmalElektrokennzeichen",
                            true
                          )
                        }
                        onReject={() =>
                          this.props.resolveConflict(
                            "MerkmalElektrokennzeichen",
                            false
                          )
                        }
                      />
                    )}

                    {isABRequest && (
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCCheckbox
                          name="ZweiraderKennzeichen"
                          label="Kennzeichen für Zweiräder"
                          checked={
                            this.props?.formValues?.ZweiraderKennzeichen?.value
                          }
                          onChange={(e) => this.props.handleChange(e, 2)}
                          disabled={this.props.readOnly}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isABRequest &&
                  this.props?.merkmalverwertungsnachweis === "1" && (
                    <div>
                      <label className={classes.columnTitle}>
                        Kennzeichenreservierung
                      </label>
                      <Grid container>
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCCheckbox
                            name="MerkmalReservierungKennzeichen"
                            label="Haben Sie ein reserviertes Kennzeichen?"
                            checked={
                              this.props?.formValues
                                ?.MerkmalReservierungKennzeichen?.value
                            }
                            onChange={(e) => this.props.handleChange(e, 2)}
                            disabled={this.props.readOnly}
                          />
                        </Grid>
                        {this.props.formValues.MerkmalElektrokennzeichen.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <DuplicateCheckboxValue
                            name="MerkmalReservierungKennzeichen"
                            label="Merkmal Reservierung Kennzeichen"
                            checked={
                              this.props.formValues
                                .MerkmalReservierungKennzeichen.duplicate
                            }
                            disabled={this.props.readOnly}
                            step={this.props.step}
                            onAccept={() =>
                              this.props.resolveConflict(
                                "MerkmalReservierungKennzeichen",
                                true
                              )
                            }
                            onReject={() =>
                              this.props.resolveConflict(
                                "MerkmalReservierungKennzeichen",
                                false
                              )
                            }
                          />
                        )}
                        {/*Hiding this Date field as per the User Story RQM-2562, it might be used later in future scope*/}
                        {/* <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel
                          label="Mein Kennzeichen ist reserviert bis"
                          isOptional
                        />
                        <MDCDatePicker
                          isManualEntry
                          value={
                            this.props.formValues.reservietesKennzeichenBis
                              .value
                          }
                          onChange={(date) => {
                            let event = {
                              target: {
                                name: "reservietesKennzeichenBis",
                                type: "date",
                                value:
                                  date !== null
                                    ? moment(date).format("YYYY-MM-DD")
                                    : null,
                              },
                            };
                            this.props.handleChange(event, 2);
                          }}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.reservietesKennzeichenBis
                              .error.length > 0 && !this.props.readOnly
                          }
                        />

                        {this.props.formValues.reservietesKennzeichenBis.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <DuplicateTextField
                            name="reservietesKennzeichenBis"
                            value={this.formatDisplayDate(
                              this.props.formValues.reservietesKennzeichenBis
                                .duplicate
                            )}
                            readOnly
                            step={this.props.step}
                            onAccept={() =>
                              this.props.resolveConflict(
                                "reservietesKennzeichenBis",
                                true
                              )
                            }
                            onReject={() =>
                              this.props.resolveConflict(
                                "reservietesKennzeichenBis",
                                false
                              )
                            }
                          />
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="reservietesKennzeichenBis-error"
                            error={
                              this.props.formValues.reservietesKennzeichenBis
                                .error
                            }
                          />
                        )}
                      </Grid> */}
                      </Grid>
                    </div>
                  )}

                {/* Ordering License Plate Section */}
                {!isABRequest &&
                  isReRegistrationRequest &&
                  this.props.formValues?.UmmeldungOldKennzeichenRetained
                    .value !== "ja" && (
                    <OrderLicensePlateSection
                      requestId={this.props?.requestId}
                      status={this.props.status}
                      operationIdsOptions={this.props.operationIdsOptions}
                    />
                  )}
              </Grid>

              {/* Ordering License Plate Section */}
              {!isABRequest &&
                (!isReRegistrationRequest ||
                  (isReRegistrationRequest &&
                    this.props.formValues?.UmmeldungOldKennzeichenRetained
                      .value === "ja")) && (
                  <Grid item xs={4}>
                    <OrderLicensePlateSection
                      requestId={this.props?.requestId}
                      status={this.props.status}
                      operationIdsOptions={this.props.operationIdsOptions}
                    />
                  </Grid>
                )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(KennzeichenRequestForm);
