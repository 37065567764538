import React, { Component } from "react";

// Components
import ErrorText from "../shared/ErrorText";
import MDCDatePicker from "../core/MDCDatePicker";

// Constants
import { STATUSARR } from "../../constants/DashboardConstants";
import {
  personTypeOptions,
  yesORnoOptions,
  vorgangsartOptions,
  VOLLMACHT_TOOLTIP_MESSAGES,
} from "../../constants/ManualRequestFormConstants";

// Utils
import { dbFormatDate } from "../../helpers/utils";

// Core Components
import VollmachtsRelevantIcon from "../core/VollmachtsRelevantIcon";
import VollmachtLoschen from "./inner_components/VollmachtLoschen";
import { DuplicateTextField } from "./DuplicateControls";
import MDCText from "../../components/core/MDCText";

import MDCRadioButton from "../core/MDCRadioButton";
import MDCSelect from "../core/MDCSelect";

// Material UI
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import MDCLabel from "../core/MDCLabel";
import theme from "../../themes/mdc-theme";
import MDCInfoIcon from "../core/MDCInfoIcon";

const styles = () => ({
  formBackGround: {
    padding: "0 3%",
    paddingTop: "4%",
    marginTop: "2% !important",
    background: `${theme.palette.MDCColors.white} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px  ${theme.palette.MDCColors.color18}`,
    borderRadius: "5px",
    opacity: 1,
    color: theme.palette.MDCColors.textColor,
    fontSize: "1rem",
    fontFamily: theme.palette.typography.fontFamily,
    lineHeight: 1,
  },
  detailViewBackground: {
    boxShadow: "none",
    paddingTop: "initial",
  },
  vollmachtCompleted: {
    paddingTop: "1%",
  },
  columnTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: theme.palette.typography.fontFamily,
    marginBottom: "1.25rem",
  },
  fieldGroup: {
    marginBottom: "1.25rem !important",
  },
  hrefSTBA: {
    color: theme.palette.MDCColors.color23,
    cursor: "pointer",
  },
  linkLabel: {
    color: theme.palette.MDCColors.color23,
    cursor: "pointer",
  },
});

class AntragRequestForm extends Component {
  formatDisplayDate = (date) => {
    let dateParts = date?.split("-");
    let displayDate = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    return displayDate;
  };

  getVorgangsartOptions = () => {
    if (this.props?.isDetailDialog) return vorgangsartOptions;
    if (this.props?.registrationTypesList?.length) {
      return this.props.registrationTypesList.map((type) => {
        return vorgangsartOptions.find((option) => {
          return option.value === type;
        });
      });
    }
    return [];
  };

  render() {
    const vorgangsart = this.props?.vorgangsart?.value;
    const isNZRequest = vorgangsart === "NZ";
    const isTZRequest = vorgangsart === "TZ";
    const isSORequest = vorgangsart === "SO";
    const isUMRequest = vorgangsart === "UM";
    const isHARequest = vorgangsart === "HA"; // Reregistration: change for car owner name

    const showAntragsNummerField =
      this.props.isDetailDialog &&
      ["Abgelehnt", "Genehmigt", "Eingereicht"].includes(
        this.props.formValues?.status?.value
      );
    const { classes, operationIdsOptions } = this.props;

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          className={`${classes.formBackGround} ${
            this.props.isDetailDialog ? classes.detailViewBackground : ""
          } ${
            this.props?.isVollmachtCompleted ? classes.vollmachtCompleted : ""
          }`}
        >
          {!this.props?.isDetailDialog && this.props?.isVollmachtCompleted && (
            <VollmachtLoschen
              handleModal={this.props.handleVollmachtLoschenChange}
              createNewRequest={this.props?.createNewRequest}
            />
          )}

          <div>
            {!this.props.isDetailDialog && (
              <>{this.props.selectDuplicateValues()}</>
            )}

            <Grid container spacing={3} marginBottom="2%">
              <Grid item xs={4} padding="1%">
                <div>
                  <label className={classes.columnTitle}>
                    Vorgangsinformationen
                  </label>
                  <Grid container>
                    <Grid item xs={12} className={classes.fieldGroup}>
                      <MDCLabel label="Vorgangsart" />
                      {this.props.isQESIconDisplay && (
                        <VollmachtsRelevantIcon
                          isQESCompleted={this.props.isVollmachtCompleted}
                        />
                      )}
                      <MDCSelect
                        value={this.props?.formValues?.vorgangsart?.value}
                        name="vorgangsart"
                        handleChange={(e) => this.props.handleChange(e, 0)}
                        disabled={
                          this.props?.isVollmachtCompleted ||
                          this.props?.readOnly
                        }
                        error={
                          this.props.formValues.vorgangsart.error.length > 0 &&
                          !this.props?.readOnly
                        }
                        options={this.getVorgangsartOptions()}
                      />
                      <ErrorText
                        id="vorgangsart-error"
                        error={this.props.formValues.vorgangsart.error}
                      />
                    </Grid>
                    {this.props.isDetailDialog && (
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="Status" />
                        <MDCText
                          name="status"
                          placeholder="status"
                          value={
                            STATUSARR[this.props.formValues?.status?.value]
                          }
                          readOnly={this.props.readOnly}
                          onChange={(e) => this.props.handleChange(e, 0)}
                        />
                      </Grid>
                    )}
                    {this.props.vorgangsart.value === "AB" && (
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel
                          label={
                            this.props.isDetailDialog
                              ? "Wunschdatum"
                              : "Wunschdatum der Außerbetriebsetzung"
                          }
                          isOptional
                        />
                        <MDCDatePicker
                          isManualEntry
                          value={this.props.formValues.datumWirksamkeit.value}
                          onChange={(date) => {
                            let event = {
                              target: {
                                name: "datumWirksamkeit",
                                type: "date",
                                value:
                                  date !== null ? dbFormatDate(date) : null,
                              },
                            };
                            this.props.handleChange(event, 0);
                          }}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.datumWirksamkeit.error
                              .length > 0 && !this.props.readOnly
                          }
                        />
                        {this.props.formValues.datumWirksamkeit.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="datumWirksamkeit"
                              value={
                                this.props.formValues.datumWirksamkeit.duplicate
                                  ? this.formatDisplayDate(
                                      this.props.formValues.datumWirksamkeit
                                        .duplicate
                                    )
                                  : ""
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "datumWirksamkeit",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "datumWirksamkeit",
                                  false
                                )
                              }
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="datumWirksamkeit-error"
                            error={this.props.formValues.datumWirksamkeit.error}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </div>
                <div>
                  <Grid container>
                    {this.props.vorgangsart.value !== "AB" && (
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="Personenform des Halters" />
                        {this.props.isQESIconDisplay && (
                          <VollmachtsRelevantIcon
                            isQESCompleted={this.props.isVollmachtCompleted}
                          />
                        )}
                        <Grid container>
                          <MDCRadioButton
                            name="personenform"
                            selected={
                              this.props?.formValues?.personenform?.value
                            }
                            options={personTypeOptions}
                            disabled={
                              this.props?.isVollmachtCompleted ||
                              this.props.readOnly
                            }
                            onChange={(e) => this.props.handleChange(e, 0)}
                            row
                          />
                        </Grid>
                      </Grid>
                    )}

                    {(this.props.isDetailDialog ||
                      this.props.formValues.großkundenantrag.value ===
                        "ja") && (
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel
                          label="Kundennr. bei der Zulassungsstelle"
                          isOptional
                        />

                        <MDCInfoIcon
                          title={
                            VOLLMACHT_TOOLTIP_MESSAGES.KUNDENNR_BEI_DER_ZULASSUNGSSTELLE_TOOLTIP
                          }
                        />
                        <MDCText
                          name="kundennummer"
                          value={this.props.formValues.kundennummer.value}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.kundennummer.error.length >
                              0 && !this.props.readOnly
                          }
                          onChange={(e) => this.props.handleChange(e, 0)}
                          maxLength={255}
                        />
                        {this.props.formValues.kundennummer.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="kundennummer"
                              value={
                                this.props.formValues.kundennummer.duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict("kundennummer", true)
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "kundennummer",
                                  false
                                )
                              }
                              maxLength={255}
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="kundennummer-error"
                            error={this.props.formValues.kundennummer.error}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.fieldGroup}>
                    <MDCLabel label="Betriebsnummer" />
                    {this.props.isQESIconDisplay && (
                      <VollmachtsRelevantIcon
                        isQESCompleted={this.props.isVollmachtCompleted}
                      />
                    )}
                    <MDCSelect
                      value={
                        this.props?.formValues?.betriebsnummern.value || ""
                      }
                      name="betriebsnummern"
                      handleChange={(e) => this.props.handleChange(e, 0)}
                      disabled={
                        this.props?.isVollmachtCompleted || this.props?.readOnly
                      }
                      error={
                        this.props.formValues?.betriebsnummern?.error?.length >
                          0 && !this.props?.readOnly
                      }
                      options={operationIdsOptions}
                      onChange={(e) => this.props.handleChange(e, 0)}
                    />
                    <ErrorText
                      id="betriebsnummern-error"
                      error={this.props.formValues.betriebsnummern.error}
                    />
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={4}>
                <div>
                  <label className={classes.columnTitle}>Vertragsdaten</label>
                  <Grid container>
                    {this.props.formValues.isVorgangsnummer.display &&
                      !this.props.isDetailDialog && (
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <Grid>
                            <MDCLabel label="Ist bereits eine Vorgangsnummer vorhanden?" />
                          </Grid>

                          <MDCRadioButton
                            name="isVorgangsnummer"
                            selected={
                              this.props.formValues?.isVorgangsnummer?.value
                            }
                            options={yesORnoOptions}
                            disabled={this.props.readOnly}
                            onChange={(e) => this.props.handleChange(e, 0)}
                            row
                          />
                        </Grid>
                      )}
                    <Grid item xs={12} className={classes.fieldGroup}>
                      <MDCLabel label="Externe Vorgangsnummer (bspw. Kommissionsnr.)" />
                      <MDCInfoIcon
                        title={
                          VOLLMACHT_TOOLTIP_MESSAGES.EXTERNE_VORGANGSNUMMER
                        }
                      />
                      {this.props.isQESIconDisplay && (
                        <VollmachtsRelevantIcon
                          isQESCompleted={this.props.isVollmachtCompleted}
                        />
                      )}

                      <MDCText
                        name="referenzID_23"
                        value={this.props.formValues.referenzID_23?.value}
                        readOnly={
                          this.props.readOnly ||
                          this.props.formValues.referenzID_23?.disabled
                        }
                        error={
                          this.props.formValues.referenzID_23?.error.length >
                            0 || this.props.emptyCheck
                        }
                        onChange={(e) => this.props.handleChange(e, 0)}
                        maxLength={50}
                      />
                      <ErrorText
                        id="interneVorgangsnummer-error"
                        error={this.props.formValues.referenzID_23?.error}
                      />
                    </Grid>

                    {this.props.vorgangsart.value !== "AB" && (
                      <>
                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="Kaufvertragsnummer" isOptional />
                          <MDCText
                            name="referenzID_24"
                            value={this.props.formValues.referenzID_24.value}
                            readOnly={this.props.readOnly}
                            error={
                              this.props.formValues.referenzID_24.error.length >
                                0 && !this.props.readOnly
                            }
                            onChange={(e) => this.props.handleChange(e, 0)}
                            maxLength={255}
                          />
                          {this.props.formValues.referenzID_24.hasOwnProperty(
                            "duplicate"
                          ) && (
                            <>
                              <DuplicateTextField
                                name="referenzID_24"
                                value={
                                  this.props.formValues.referenzID_24.duplicate
                                }
                                readOnly
                                step={this.props.step}
                                onAccept={() =>
                                  this.props.resolveConflict(
                                    "referenzID_24",
                                    true
                                  )
                                }
                                onReject={() =>
                                  this.props.resolveConflict(
                                    "referenzID_24",
                                    false
                                  )
                                }
                                maxLength={255}
                              />
                            </>
                          )}
                          {!this.props.readOnly && (
                            <ErrorText
                              id="Kaufvertragsnummer-error"
                              error={this.props.formValues.referenzID_24.error}
                            />
                          )}
                        </Grid>

                        <Grid item xs={12} className={classes.fieldGroup}>
                          <MDCLabel label="Leasingvertragsnummer" isOptional />{" "}
                          <MDCText
                            name="referenzID_25"
                            value={this.props.formValues.referenzID_25.value}
                            readOnly={this.props.readOnly}
                            error={
                              this.props.formValues.referenzID_25.error.length >
                                0 && !this.props.readOnly
                            }
                            onChange={(e) => this.props.handleChange(e, 0)}
                            maxLength={255}
                          />
                          {this.props.formValues.referenzID_25.hasOwnProperty(
                            "duplicate"
                          ) && (
                            <>
                              <DuplicateTextField
                                name="referenzID_25"
                                value={
                                  this.props.formValues.referenzID_25.duplicate
                                }
                                readOnly
                                step={this.props.step}
                                onAccept={() =>
                                  this.props.resolveConflict(
                                    "referenzID_25",
                                    true
                                  )
                                }
                                onReject={() =>
                                  this.props.resolveConflict(
                                    "referenzID_25",
                                    false
                                  )
                                }
                                maxLength={255}
                              />
                            </>
                          )}
                          {!this.props.readOnly && (
                            <ErrorText
                              id="referenzID_25-error"
                              error={this.props.formValues.referenzID_25.error}
                            />
                          )}
                        </Grid>
                      </>
                    )}

                    {showAntragsNummerField && (
                      <Grid item xs={12} className={classes.fieldGroup}>
                        <MDCLabel label="Antragsnummer" />
                        <MDCText
                          name="antragsnummer"
                          value={this.props.formValues.antragsnummer?.value}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.antragsnummer?.error.length >
                              0 && !this.props.readOnly
                          }
                          onChange={(e) => this.props.handleChange(e, 0)}
                          maxLength={255}
                        />
                        {this.props.formValues.antragsnummer.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="antragsnummer"
                              value={
                                this.props.formValues.antragsnummer?.duplicate
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "antragsnummer",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "antragsnummer",
                                  false
                                )
                              }
                              maxLength={255}
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="antragsnummer-error"
                            error={this.props.formValues.antragsnummer?.error}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={4}>
                <div>
                  <label className={classes.columnTitle}>
                    Zulassungsbehörde
                  </label>
                  <Grid container>
                    <Grid item xs={12} marginBottom="1.25rem">
                      <a
                        rel="noopener noreferrer"
                        href="https://www.xrepository.de/details/urn:xoev-de:bund:kba:codeliste:stba_01.04.2019"
                        target="_blank"
                        className={classes.hrefSTBA}
                      >
                        <MDCLabel
                          label="Nummer der Zulassungsstelle (STBA)"
                          isOptional
                        />
                      </a>
                      <MDCText
                        name="behoerdeKreisschluessel"
                        value={
                          this.props.formValues.behoerdeKreisschluessel.value
                        }
                        readOnly={this.props.readOnly}
                        error={
                          this.props.formValues.behoerdeKreisschluessel.error
                            .length > 0 && !this.props.readOnly
                        }
                        onChange={(e) => this.props.handleChange(e, 0)}
                        maxLength={7}
                      />
                      {this.props.formValues.behoerdeKreisschluessel.hasOwnProperty(
                        "duplicate"
                      ) && (
                        <>
                          <DuplicateTextField
                            name="behoerdeKreisschluessel"
                            value={
                              this.props.formValues.behoerdeKreisschluessel
                                .duplicate
                            }
                            readOnly
                            step={this.props.step}
                            onAccept={() =>
                              this.props.resolveConflict(
                                "behoerdeKreisschluessel",
                                true
                              )
                            }
                            onReject={() =>
                              this.props.resolveConflict(
                                "behoerdeKreisschluessel",
                                false
                              )
                            }
                            maxLength={5}
                          />
                        </>
                      )}
                      {!this.props.readOnly && (
                        <ErrorText
                          id="behoerdeKreisschluessel-error"
                          error={
                            this.props.formValues.behoerdeKreisschluessel.error
                          }
                        />
                      )}
                    </Grid>
                    {(isNZRequest ||
                      isTZRequest ||
                      isSORequest ||
                      isUMRequest ||
                      isHARequest) && (
                      <Grid item xs={12} marginBottom={"1rem"}>
                        <MDCLabel
                          label={`Wunschdatum der ${
                            isNZRequest
                              ? "Neuzulassung"
                              : isTZRequest
                              ? "Tageszulassungs"
                              : isUMRequest
                              ? "Ummeldung"
                              : isHARequest
                              ? "Namens- oder Adressänderung des Halters"
                              : "Wiederzulassung"
                          }`}
                          isOptional
                        />
                        <MDCDatePicker
                          disablePastDate={
                            this.props?.formValues?.vorgangsart?.value === "SO"
                              ? false
                              : true
                          }
                          isManualEntry
                          value={this.props.formValues.DatumErstzulassung.value}
                          onChange={(date) => {
                            let event = {
                              target: {
                                name: "DatumErstzulassung",
                                type: "date",
                                value:
                                  date !== null ? dbFormatDate(date) : null,
                              },
                            };
                            this.props.handleChange(event, 0);
                          }}
                          readOnly={this.props.readOnly}
                          error={
                            this.props.formValues.DatumErstzulassung.error
                              .length > 0 && !this.props.readOnly
                          }
                        />
                        {this.props.formValues.DatumErstzulassung.hasOwnProperty(
                          "duplicate"
                        ) && (
                          <>
                            <DuplicateTextField
                              name="DatumErstzulassung"
                              value={
                                this.props.formValues.DatumErstzulassung
                                  .duplicate
                                  ? this.formatDisplayDate(
                                      this.props.formValues.DatumErstzulassung
                                        .duplicate
                                    )
                                  : ""
                              }
                              readOnly
                              step={this.props.step}
                              onAccept={() =>
                                this.props.resolveConflict(
                                  "DatumErstzulassung",
                                  true
                                )
                              }
                              onReject={() =>
                                this.props.resolveConflict(
                                  "DatumErstzulassung",
                                  false
                                )
                              }
                            />
                          </>
                        )}
                        {!this.props.readOnly && (
                          <ErrorText
                            id="DatumErstzulassung-error"
                            error={
                              this.props.formValues.DatumErstzulassung.error
                            }
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AntragRequestForm);
